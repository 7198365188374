import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FilterProps } from '../../components/templates/_interface';
import { SurveyStyle } from '../../components/templates/_style';
import DesignHeader from '../../components/templates/design/DesignHeader';
import DesignContent from '../../components/templates/design/DesignContent';
import query from '../../hooks/RQuery/utils';
import { useTranslation } from 'react-i18next';
import PinHeader from '../../components/templates/smartpin/PinHeader';
import ListFilter from '../../components/templates/survey/ListFilter';
import Input from '../../components/atoms/Input';
import IcoInputSearch from '../../assets/images/ico-input-search.svg';
import SurveyTable from '../../components/templates/survey/SurveyTable';
import PinTable from '../../components/templates/smartpin/PinTable';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { progressState } from '../../store/Atoms';
import { dragDropBoxPage, popupResultState } from '../../store/popupAtoms';
import { showResultPopup } from '../../components/templates/smartpin/common.func';
import PopupResult from '../../components/templates/survey/popup/PopupResult';

const PinRegister = () => {
  const [progressBar, setProgressBar] = useRecoilState(progressState);
  const resetDndPg = useResetRecoilState(dragDropBoxPage);
  const [dndPgState, setdndPgState] = useRecoilState(dragDropBoxPage);

  const param = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  //현재 페이지의 siteId, siteName, 관종 리스트 저장
  const [siteInfo, setSiteInfo] = useState({
    id: '',
    name: '',
    facilityKindList: '',
  });
  // const { mutate: mutateDesign } = query.designs.useUploadDesign({ siteId: siteInfo.id, facilityKind: categoryTabList.find(({ active }) => active)?.key, file: dxfFile });
  const {mutate:mutatePins} = query.smartpin.pin.useUploadPins({siteId:siteInfo.id})


  //목록가기
  const onBack = () => {
    navigate('/pin/list');
  };

  useEffect(() => {
    setSiteInfo({
      id: param.id || String(sessionStorage.getItem('survey_siteId')),
      name: param.name || String(sessionStorage.getItem('survey_siteName')),
      facilityKindList: param.facilityKindList || String(sessionStorage.getItem('survey_facilityKindList')),
    });
  }, []);


  // 초기화
  useEffect(() => {
    resetDndPg();
  }, []);
  const onChangeFile = (e) => {
    const file = e.target.files[0];
    mutatePins({csvFile:file},{
      onSettled:(data:any, error:any) => {
        // 로더 제거
        setProgressBar((prev) => ({ ...prev, show: false }));
        console.log(error?.response?.data.errorMsg);

        // dnd 영역 데이터 초기화
        resetDndPg();

        // 결과 처리 - 팝업
        showResultPopup({ type: dndPgState.type, data, error });
      }
    })
    // mutateDesign({ siteId: siteInfo.id, facilityKind: categoryTabList.find(({ active }) => active)!.key, file: file });
  };

  return (
    <>
      {siteInfo.id ? (
        <SurveyStyle.Contain>
          <SurveyStyle.Inner>
            {/* 공통헤더 */}
            <PinHeader onBack={onBack} siteInfo={siteInfo} onChangeFile={onChangeFile} />
            <SurveyStyle.Wrap style={{ height: 'calc(100% - 84px)' }}>
              <SurveyStyle.DescArea>
                <SurveyStyle.DescWrap style={{ height: '100%' }}>
                  <SurveyStyle.TableArea>
                    <SurveyStyle.TabArea>
                      <SurveyStyle.BtnMap type={'button'} onClick={() => {}}></SurveyStyle.BtnMap>
                    </SurveyStyle.TabArea>
                    <PinTable siteInfo={siteInfo} />
                  </SurveyStyle.TableArea>
                </SurveyStyle.DescWrap>
              </SurveyStyle.DescArea>
            </SurveyStyle.Wrap>
          </SurveyStyle.Inner>
          {/* 성공 or 실패 팝업 */}
          <PopupResult />
        </SurveyStyle.Contain>
      ) : null}
    </>
  );
};

export default PinRegister;
