// 설계도면
import { request, requestFormData } from '../../../service/api/common';
import * as apiUrl from './apiUrl';

//스마트핀 파이프 불러오기
export const getPipes = async ({ siteId }) => {
  const { data } = await request('kcity').get(apiUrl.smartpin.pipe, { params: { siteId } });
  return data.data;
};

//스마트핀 핀 불러오기
export const getMapPins = async ({ siteId }) => {
  const { data } = await request('kcity').get(apiUrl.smartpin.pin, { params: { siteId } });
  return data.data;
};

//스마트핀 맨홀 불러오기
export const getHoles = async ({ siteId }) => {
  const { data } = await request('kcity').get(apiUrl.smartpin.hole, { params: { siteId } });
  return data.data;
};

//스마트핀 목록 불러오기
export const getPins = async ({ siteId, pageSize, pageNum, sort }) => {
  const { data } = await request('kcity').get(apiUrl.smartpin.list, { params: { siteId, pageSize, pageNum, sort } });
  return data.data;
};

//스마트핀 이미지 업로드(단일)
export const uploadPinImage = async ({ siteId, serial, image }) => {
  const formData = new FormData();
  formData.append('image', image);
  const { data } = await requestFormData('kcity').post(apiUrl.smartpin.image, formData, {
    params: { siteId, serial },
  });
  return data.data;
};

//스마트핀 이미지 삭제(단일)
export const deletePinImage = async ({ siteId, serial }) => {
  const { data } = await request('kcity').delete(apiUrl.smartpin.image, {
    params: { siteId, serial },
  });
  return data.data;
};

//스마트핀 csv업로드
export const uploadPins = async ({ siteId, csvFile }) => {
  const formData = new FormData();
  formData.append('csvFile', csvFile);
  const { data } = await requestFormData('kcity').post(apiUrl.smartpin.pin, formData, {
    params: { siteId },
  });
  return data.data;
};

