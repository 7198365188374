import React, { Fragment, useCallback, useEffect, useState } from 'react';
// recoil
import { useRecoilState } from 'recoil';
import { constructionInfoState, siteListState } from 'store/Atoms';
// ol-map
import { FullScreen, defaults as defaultControls } from 'ol/control.js';
import OlCore from 'maps/wfs/OlCore';
import ObjectMapPipeLine from 'maps/wfs/Object/MapPipeLine';
import ObjectMapPoint from 'maps/wfs/Object/MapPoint';
import DrawLayer from 'maps/wfs/Layer/DrawLayer';

import LogoMap4D from '../assets/images/2dMap/logo-map4d.svg';

// comp
import SettingBtn from 'components/templates/2dMap/SettingBtn';
import SettingWrap from 'components/templates/2dMap/SettingWrap';
import PipeInfoModal from 'components/templates/2dMap/PipeInfoModal';
import InitPositionBtn from 'components/templates/2dMap/Toolbar';
import PointInfoModal from 'components/templates/2dMap/PointInfoModal';
import ChangeMapBtn from 'components/templates/2dMap/ChangeMapBtn';
import { useLocation, useNavigate } from 'react-router-dom';

import { qsData } from 'service/api/common';
import Toolbar from 'components/templates/2dMap/Toolbar';
import query from 'hooks/RQuery/utils';
import Design from 'maps/wfs/Object/Design';
import useMapAutoFocus from 'hooks/2dMap/useMapAutoFocus';
import EditInfoModal from 'components/templates/2dMap/EditInfoModal';
import DragBoxInteraction from 'maps/wfs/Interaction/DragBoxInteraction';
import { useTranslation } from 'react-i18next';
import { View } from 'ol';
import MapScale from '../components/atoms/MapScale';
import ObjectMapShp from '../maps/wfs/Object/MapShp';
import { useShps } from '../hooks/RQuery/utils/shps';
// import Pin from '../maps/wfs/SmpartPin/Pin';

// 2.5D 지도
const Map = () => {
  const { t, i18n } = useTranslation();
  const [mapScaleNum, setMapScaleNum] = useState('0 m');
  const myInfo = query.auths.useMyInfo();

  const siteId = sessionStorage?.getItem('siteId') || '';
  const [siteList] = useRecoilState(siteListState);
  const facilityKinds = siteList.filter(({ active }) => active)[0]?.facilityKinds || []; // 볼 수 있는 관 종류(해당 현장에서)
  const code = query.codes.useCodes();
  const shpData = query.shps.useShps(siteId);
  const [editMode, setEditMode] = useState(false); //편집모드
  //   const [isMultiSelect, setIsMultiSelect] = useState(false); // 파이프 복수선택 토글
  const [showSetting, setShowSetting] = useState(false); // 지도 설정 박스
  //   const [showEditBox, setShowEditBox] = useState(false); // 편집 툴 박스
  const [mapInstance, setMapInstance] = useState<OlCore>();
  const [line, setLine] = useState<ObjectMapPipeLine>();
  const [point, setPoint] = useState<ObjectMapPoint>();
  const [design, setDesign] = useState<Design>();
  const [shp, setShp] = useState<ObjectMapShp>();
  //   const [draw, setDraw] = useState<DrawLayer>();
  //   const [undoredo, setUndoredo] = useState<any>(null); // 삭제예정
  //   const [drawMode, setDrawMode] = useState(false); // draw 활성화 여부

  const [isObjectReady, setIsObjectReady] = useState(false);

  const [pointLayers, setPointLayers] = useState([]);
  const [pipeLayers, setPipeLayers] = useState([]);

  const [pipeInfo, setPipeInfo] = useState<any | null>(null); // 파이프 라인 상세 정보
  const [pointInfo, setPointInfo] = useState<any>(null); // 시점 종점 상세 정보

  const [selectedLines, setSelectedLines] = useState<any[]>([]); //편집할 대상 피쳐들 Line
  const [selectedPoints, setSelectedPoints] = useState<any[]>([]); //편집할 대상 피쳐들 Point

  const pinsData = query.smartpin.pin.useMapPins({ siteId: siteId });
  //   // 파이프 조회 (상수)
  //  const { data: wtlPipeMapData, isSuccess: wtlPipesSuccess } = query.pipes.wtl.usePipes({ siteId: siteId });
  //   // 포인트 조회 (상수)
  //  const { data: wtlPointsData, isSuccess: wtlPointsSuccess } = query.points.wtl.usePoints({ siteId: siteId });

  // * "요청가능한" 관 종류의 "파이프" 데이터 모두 요청
  const { data: mapPipesData } = query.map.pipes.useMapPipes({ siteId, facilityKinds });

  // * "요청가능한" 관 종류의 "측점" 데이터 모두 요청
  const { data: mapPointsData } = query.map.points.useMapPoints({ siteId, facilityKinds });

  // * 포인트 "상세" 정보 조회(공통)
  const { data: ptDetailData } = query.map.points.useMapPointDetail({ pointInfo });

  // 설계도면 가져오기
  const { data: designData, isSuccessAll: designSuccessAll } = query.designs.useGetDesignDetail({ siteId, facilityKinds });
  //Openlayers 초기화
  useEffect(() => {
    if (!document.querySelector('.ol-viewport')) {
      const core = new OlCore();
      const line = new ObjectMapPipeLine(core);
      const point = new ObjectMapPoint(core);
      const design = new Design(core);
      const shp = new ObjectMapShp(core);
      // const draw = new DrawLayer(core);
      // const objectPin = new Pin(core);

      // ** FullScreen 컨트롤 삭제
      core.mapInstance.getControls().forEach(function (control) {
        if (control instanceof FullScreen) {
          core.mapInstance.removeControl(control);
        }
      });

      setMapInstance(core);
      setLine(line);
      setPoint(point);
      setDesign(design);
      setShp(shp);
      // setDraw(draw);
      // setObjectPin(objectPin);

      setIsObjectReady(true);
    }
  }, []);

  useEffect(() => {
    if (isObjectReady) {
      mapInstance?.onMoveEnd(() => {
        setMapScaleNum(document.getElementsByClassName('ol-scale-line-inner')[0].innerHTML || '0 m');
        console.log(mapInstance?.getMapInstance().getView().getViewStateAndExtent());
      });
    }
  }, [isObjectReady]);

  useEffect(() => {
    mapInstance?.getMapInstance().setView(
      new View({
        projection: String(sessionStorage.getItem('projection')),
      }),
    );
    // mapInstance?.mapChange(mapInstance!.osmMapSource);
  }, [siteList.findIndex((i) => i.active)]);

  // 데이터 자동 선택 (포인트 | 파이프)
  const autoFocusData = useCallback(
    async ({ type, facilityKind, id, siteId }: { type: 'pipe' | 'point'; facilityKind: string; id: string; siteId?: string }) => {
      console.log(siteId);
      siteId && sessionStorage.setItem('siteId', siteId.split(',')[0]);
      let targetData: any = null;
      if (type === 'pipe') {
        targetData = mapPipesData?.[facilityKind]?.find((ele) => ele.id === id);
      }
      if (type === 'point') {
        targetData = mapPointsData?.[facilityKind]?.find((ele) => ele.pointId === id);
      }

      // 해당하는 데이터를 찾았을때
      if (targetData) {
        // 1) 파이프
        if (type === 'pipe') {
          // 상세 정보창 오픈
          setPipeInfo({ ...targetData });
          // 선택 활성화 (select)
          setTimeout(() => {
            line?.autoSelectLine(targetData); //(+ view fit)
          }, 100);
        }
        // 2) 포인트
        if (type === 'point') {
          // 상세 정보창 오픈
          setPointInfo({ ...targetData });
          // 선택 활성화 (select)
          setTimeout(() => {
            point?.autoSelectLine(targetData); //(+ view fit)
          }, 100);
        }
      }

      return targetData;
    },
    [line, point, mapPipesData, mapPointsData],
  );

  // ---- [다른곳에서 넘어오는 경우] 상세정보 자동 표시 ---- //
  // 1) 작업일보 -> 2D 지도
  // 2) 유니티 -> 2D 지도
  useMapAutoFocus(autoFocusData);

  // siteId 변경시
  useEffect(() => {
    closePipInfo(); // 파이프 선택 해제 및 창닫기
    closePtInfo(); // 포인트 선택 해제 및 창닫기
  }, [siteId]);
  // point 레이어 종류 가져오기
  useEffect(() => {
    if (isObjectReady) {
      let pointsLayers: any = point?.pointsLayers;
      setPointLayers(pointsLayers); // point object에서 레이어 종류 추출
    }
  }, [isObjectReady, point]);

  // pipe 레이어 종류 가져오기
  useEffect(() => {
    if (isObjectReady) {
      let pipeLayers: any = line?.pipeLayers;
      setPipeLayers(pipeLayers);
    }
  }, [isObjectReady, line]);

  // 포인트 데이터 그리기 (+ 초기 위치 이동)
  useEffect(() => {
    if (isObjectReady) {
      point!.drawFacilityAll(mapPointsData);
    }
  }, [isObjectReady, point, mapPointsData]);

  // 파이프 데이터 그리기
  useEffect(() => {
    if (isObjectReady) {
      console.log('-0------');
      console.log(mapPipesData);
      line!.drawFacilityAll(mapPipesData);
    }
  }, [isObjectReady, line, mapPipesData]);

  useEffect(() => {
    if (isObjectReady && shpData.data) {
      shp!.drawPipe(shpData.data);
    }
  }, [isObjectReady, shpData.data]);

  //스마트핀 그리기
  useEffect(() => {
    if (isObjectReady && pinsData.data) {
      point?.drawPin(pinsData.data);
      if (pointInfo?.serial) {
        point?.selectAfterRefetch(pointInfo.serial, setPointInfo);
      }
    }
  }, [isObjectReady, pinsData.data]);

  /* --- 이벤트 연결 (시작) --- */

  // 1. 포인트 상세창 열기(or 닫기) (시점,종점 클릭 | 포인트 검색)
  const openPointInfo = useCallback((prop?: any | null) => {
    setPointInfo(prop); // 상세창 오픈 or 닫기
  }, []);

  // 2. 포인트 상세창 열기(or 닫기) (맨홀|밸브|소화전 클릭(시점,종점X))
  const onClickVMF = useCallback(
    async (prop?: any | null) => {
      if (prop?.pointId) {
        // * 마커가 겹쳐서 파이프가 동시에 선택될때, 파이프 선택은 리셋하기
        let isPipe = await line?.selectClick.getFeatures().getArray().length;
        if (isPipe) {
          line?.selectedClear(); // 파이프 선택 해제
          setPipeInfo(null);
        }
      }

      setPointInfo(prop); // 상세창 오픈 or 닫기
    },
    [line],
  );

  // 파이프 라인 선택 이벤트
  const onClickLine = useCallback(
    async (prop?: any) => {
      if (prop) {
        // * 맨홀 등 마커가 겹쳐서 동시에 선택될때
        //   let selectedObjectPt = await point?.selectClickPt.getFeatures().getArray().length;
        //   if (selectedObjectPt) {
        //     point?.selectedClearPt();
        //     setPointInfo(null);
        //   }
      }
      setPipeInfo(prop);
    },
    [point],
  );

  // 포인트 이벤트(한번만 추가_의존⬇︎)
  useEffect(() => {
    if (isObjectReady) {
      point?.addMapClickEvent(); // 지도 클릭 이벤트 - 포인트
      point?.addMapMoveEvent(); // 지도 마우스 무브 이벤트 - 포인트
      point?.addClickPoint(onClickVMF); // VMF 포인트 클릭
    }
  }, [isObjectReady, point, onClickVMF]);

  // 파이프 이벤트(한번만 추가_의존⬇︎)
  useEffect(() => {
    if (isObjectReady) {
      line?.addMapClickEvent(); // 지도 클릭 이벤트 추가
      line?.addClick(onClickLine); // 파이프 라인 클릭
      line?.addClickPoint(openPointInfo); // 시점,종점 포인트 클릭
    }
  }, [isObjectReady, line, openPointInfo, onClickLine]);

  // 수정예정) multiSelect 콜백 함수 (선택된 피쳐 배열)
  const onClickMultiLine = useCallback((features) => {
    let lines = features?.map((feature) => {
      return feature?.getProperties()?.properties;
    });
    setSelectedLines(lines);
  }, []);

  const onClickMultiPt = useCallback((features) => {
    let points = features?.map((feature) => {
      return feature?.getProperties()?.properties;
    });
    setSelectedPoints(points);
  }, []);

  // 수정예정) multi select - 포인트
  useEffect(() => {
    if (isObjectReady) {
      point?.multipleSelect(onClickMultiPt); // multiSelect
      point?.onToggleMultiSelect(false); // multiSelect - 기본 끄기
    }
  }, [isObjectReady, point, onClickMultiPt]);

  // 수정예정) multi select - 파이프
  useEffect(() => {
    if (isObjectReady) {
      line?.multipleSelect(onClickMultiLine); // multiSelect
      line?.onToggleMultiSelect(false); // multiSelect - 기본 끄기
    }
  }, [isObjectReady, line, onClickMultiLine]);

  /* --- 이벤트 연결 (끝) --- */

  // 설계도면
  useEffect(() => {
    if (isObjectReady) {
      design?.draw(designData);
    }
  }, [isObjectReady, design, designData, siteId]);

  // [포인트] 상세창 닫기
  const closePtInfo = useCallback(() => {
    setPointInfo(null); // 정보 초기화

    point?.selectedClearPt(); // VMF 포인트 선택 해제
    line?.selectedClearPt(); // 시점,종점 포인트 선택 해제

    // * active 마커 - 이전 파이프 중심 위치로 변경
    if (pipeInfo) {
      let stCoord = pipeInfo?.stCoordinate;
      let endCoord = pipeInfo?.endCoordinate;
      line?.redrawActivePoint({ x: stCoord.x, y: stCoord.y }, { x: endCoord.x, y: endCoord.y });
    }
  }, [line, point, pipeInfo]);

  // [파이프] 상세창 닫기
  const closePipInfo = useCallback(() => {
    line?.selectedClear(); // 파이프 선택 해제
    setPipeInfo(null); // 정보 초기화
  }, [line]);

  // 뷰 처음 위치로 가기([현장위치] 버튼 클릭)
  const goInitPosition = () => {
    point?.goToWholeExtent(); // 마지막 측점 레이어 기준
  };

  // [마지막 시공위치] 버튼 클릭
  const goConstruct = () => {
    let ptData = point?.lastPtData || {}; // 측점 데이터 중에서 마지막 ptNum 위치
    const { facilityKind, pointId } = ptData;
    if (pointId) {
      autoFocusData({ type: 'point', facilityKind, id: pointId });
    }
  };

  //레이어 토글
  const toggleLayer = async (layerId: string) => {
    // 파이프 정보 켜져있을때
    if (pipeInfo) {
      // 체크박스 누른 facility가 같은 정보창일때 창 끄기
      if (pipeInfo?.facilityKind === layerId) {
        // 포인트 정보 닫기
        if (pointInfo) {
          await closePtInfo();
        }
        // 파이프 정보 닫기
        await closePipInfo();
      }
    }

    line?.onToggleLayer(layerId); // 파이프
    point?.onToggleLayer(layerId); // 포인트
  };

  // 버퍼 토글
  const toggleBuffer = useCallback(
    (bool: boolean) => {
      line?.onToggleBuffer(bool); // 파이프
    },
    [line],
  );
  // 지도 전환
  const onChangeMapType = (type: 'map' | 'satellite') => {
    if (type === 'map') {
      if (myInfo?.data?.country === 'VN') {
        mapInstance?.mapChange(mapInstance!.map4dMapSource);
      } else {
        mapInstance?.mapChange(mapInstance!.osmMapSource);
      }
    } else {
      mapInstance?.mapChange(mapInstance!.googleMapSource);
    }
  };

  // [편집모드] On,Off 상태에 따라 필요한 부수 처리
  const toggleEditTool = (active: boolean) => {
    //  setEditMode(active); // info창 오픈
    //ON
    if (active) {
      if (pointInfo) closePtInfo(); // 포인트 정보 닫기
      if (pipeInfo) closePipInfo(); // 파이프 정보 닫기

      point?.toggleSelect(false); //  VMF 포인트 선택 기능 OFF
      line?.toggleSelect(false); // 관로,포인트(시점,종점) 선택 기능 OFF
      // TODO: 측점 스타일 변경
    }
    //OFF
    if (!active) {
      point?.toggleSelect(true); //  VMF 포인트 선택 기능 ON
      line?.toggleSelect(true); // 관로,포인트(시점,종점) 선택 기능 ON

      setSelectedLines([]); // 선택된 파이프 state 초기화
      setSelectedPoints([]); // 선택된 포인트 state 초기화
    }
  };

  //관종별 설계도면 토글
  const toggleDesign = (key) => {
    design?.toggleDesign(key);
  };

  //스마트핀 레이어 토글
  const togglePin = () => {
    point?.onTogglePin();
  };

  return (
    <>
      <SettingBtn isOpen={showSetting} openPointInfo={openPointInfo} point={point} toggleEditTool={toggleEditTool} core={mapInstance} />
      <div style={{ width: '100%', height: '100%', position: 'relative' }} id={'mapContainer'}>
        {/* 지도 설정 펼치기 버튼 + 측점검색 */}

        {/* 지도 설정 */}
        <SettingWrap showSetting={showSetting} toggleLayer={toggleLayer} toggleBuffer={toggleBuffer} setShowSetting={setShowSetting} toggleDesign={toggleDesign} togglePin={togglePin} />

        {/* 툴바 */}
        <Toolbar onClick={goInitPosition} is2DMap={true} point={point} goConstruct={goConstruct} />

        {/* 지도 타입 변경 버튼 */}
        <ChangeMapBtn onClick={onChangeMapType} />

        <MapScale mapScaleNum={mapScaleNum} id={'scaleBar'} />

        {/* 포인트 상세창 */}
        {pointInfo && <PointInfoModal info={{ ...ptDetailData, ...pointInfo }} facilityKind={pointInfo?.facilityKind} close={closePtInfo} pinData={pinsData} />}

        {/* 파이프 상세창 */}
        {pipeInfo && !pointInfo && <PipeInfoModal info={pipeInfo} close={closePipInfo} />}

        {editMode && <EditInfoModal selectedLines={selectedLines} selectedPoints={selectedPoints} />}

        {myInfo.isSuccess && myInfo.data.country === 'VN' && <img style={{ position: 'absolute', left: 16, bottom: 40, zIndex: 1 }} src={LogoMap4D} alt={'Map4D'} />}
      </div>
    </>
  );
};

export default Map;
