import React, { Fragment, useEffect, useState } from 'react';
import LabelInput, { LabelForm } from '../../components/atoms/LabelInput';
import Checkbox from '../../components/atoms/Checkbox';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { confirmOneBtn, popupAddSite } from '../../store/popupAtoms';
import query from '../../hooks/RQuery/utils';
import useInputChange from '../../hooks/useInputChange';
import useMutateHook from '../../hooks/RQuery/useMutateHook';
import { postSurveyAddSite } from '../../service/api/common';
import { DataFilterStyle, SurveyStyle } from '../../components/templates/_style';
import { useSiteAdd } from '../../hooks/RQuery/utils/sites';
import { useNavigate } from 'react-router-dom';
import ManageContent, { Dash, ManageContentStepProps, Solid } from '../../components/templates/ManageContent';
import styled, { useTheme } from 'styled-components';
import Input from '../../components/atoms/Input';
import IcoNextLight from '../../assets/images/ico-next-light.svg';
import IcoNext from '../../assets/images/ico-next.svg';
import { SelectProps } from '../_interface';
import { siteListState, themeState } from '../../store/Atoms';
import DatePicker from 'react-multi-date-picker';
import { useTranslation } from 'react-i18next';
import { MonthsForLocale } from '../../util/global';
import Select from '../../components/atoms/Select';
import { SurveyHeaderSt } from '../../components/templates/popup/_style';
import ConfirmOneBtn from '../../components/templates/ConfirmOneBtn';

export const WorkPlaceStyle = {
  FacilityArea: styled.div`
    display: flex;
    align-items: center;
  `,
  FacilityColumn: styled.div`
    &:not(:last-child) {
      margin-right: 16px;
    }
  `,
  FacilityItem: styled.div`
    display: flex;
    align-items: center;
    width: 400px;
    height: 64px;
    box-sizing: border-box;
    border: 1px solid ${(p) => p.theme.form.cont.facility.border};
    background: ${(p) => p.theme.form.cont.facility.bg};
    border-radius: 4px;
    &:not(:last-child) {
      margin-bottom: 4px;
    }
  `,
  FacilityCheckArea: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 100%;
    border-right: 1px solid ${(p) => p.theme.form.cont.facility.border};
  `,
  FacilityRowGroup: styled.div`
    display: flex;
    height: 100%;
    flex: 1;
    flex-direction: column;
  `,
  FacilityRow: styled.div`
    flex: 1;
    padding: 0 16px;
    display: flex;
    align-items: center;
    &:first-child {
      border-bottom: 1px solid ${(p) => p.theme.form.cont.facility.border};
    }
  `,
  SiteName: styled.strong`
    font-size: 16px;
    color: ${(p) => p.theme.form.cont.facility.siteName};}
    font-weight: 500;
  `,
};

const initForm = {
  siteName: '',
  projection: '',
  projectName: '',
  address: '',
  observer: '',
  constructionStartDate: '',
  constructionEndDate: '',
};
const initFormConstInfo = {
  companyName: '',
  managerName: '',
  email: '',
  officeNumber: '',
  phoneNumber: '',
};

const WorkPlaceRegister = () => {
  const { t, i18n } = useTranslation();
  const monthsKo = MonthsForLocale(i18n.language === 'ko' ? 'ko' : 'en', 'short');
  const [themeMode] = useRecoilState(themeState);

  //메뉴(step형태)
  const [step, setStep] = useState<ManageContentStepProps[]>([
    { num: 1, pass: true, text: '사업장정보' },
    { num: 2, pass: false, text: '발주처정보' },
    { num: 3, pass: false, text: '시공사정보' },
    { num: 4, pass: false, text: '용역사정보' },
  ]);
  const [epsgList, setEpsgList] = useState<SelectProps[]>([{ text: t('선택'), value: '', active: true }]);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const passArr = step.filter(({ pass }) => pass);
  const [siteList, setSiteList] = useRecoilState(siteListState);
  const navigate = useNavigate();
  const [confirmOneBtnState, setConfirmOneBtn] = useRecoilState(confirmOneBtn);

  // 기본 정보
  const [formOrigin, setFormOrigin, onChangeOrigin, resetAll, resetOne, onChangeDate] = useInputChange(initForm);
  // constructionInfo : owner
  const [formOwner, setFormOwner, onChangeOwner] = useInputChange(initFormConstInfo);
  // constructionInfo : contractor
  const [formCont, setFormCont, onChangeCont] = useInputChange(initFormConstInfo);
  // constructionInfo : subContractor
  const [formSubCont, setFormSubCont, onChangeSubCont] = useInputChange(initFormConstInfo);

  const setPopupAddSite = useSetRecoilState(popupAddSite);

  const code = query.codes.useCodes(); // 코드 조회
  const facilityList = code?.data?.FACILITY;
  //   console.log(facilityList);

  const [facilityOption, setFacilityOption] = useState([{ text: '', value: '', amount: 0, color: '', checked: false }]);
  //   console.log(facilityOption);

  //   const [date, setDate] = useState<DateObject>(new DateObject());
  const myInfo = query.auths.useMyInfo();
  // const { mutate: mutateAddSite } = useMutateHook({ fetchApi: postSurveyAddSite, invalidQuery: [[]] });
  const { mutate: mutateAddSite } = query.sites.useSiteAdd({
    ...formOrigin,
    facilityKindList: facilityOption
      ?.filter((ele) => ele.checked)
      ?.map((ele) => {
        return { facilityKind: ele.value, planAmount: ele.amount || 0 };
      }),
    constructionInfo: {
      owner: {
        ...formOwner,
      },
      contractor: {
        ...formCont,
      },
      subContractor: {
        ...formSubCont,
      },
    },
  });

  //좌표계 목록 set TODO 서버에서 받아 세팅예정
  useEffect(() => {
    const newArr: SelectProps[] = [...epsgList];
    newArr.push({ text: 'EPSG:5176', value: 'EPSG:5176', active: false });
    newArr.push({ text: 'EPSG:5179', value: 'EPSG:5179', active: false });
    newArr.push({ text: 'EPSG:5183', value: 'EPSG:5183', active: false });
    newArr.push({ text: 'EPSG:5185', value: 'EPSG:5185', active: false });
    newArr.push({ text: 'EPSG:5186', value: 'EPSG:5186', active: false });
    newArr.push({ text: 'EPSG:5187', value: 'EPSG:5187', active: false });
    newArr.push({ text: 'EPSG:9214', value: 'EPSG:9214', active: false });
    newArr.push({ text: 'EPSG:4326', value: 'EPSG:4326', active: false });
    setEpsgList(newArr);
  }, []);

  useEffect(() => {
    if (facilityList[0]) {
      let newList = facilityList?.map((ele) => ({
        text: ele?.value,
        value: ele?.code,
        checked: false,
        color: ele?.color,
        amount: 0,
      }));
      setFacilityOption(newList);
    }
  }, [facilityList]);

  // 등록하기 mutate
  const serverAddSite = async () => {
    let checkedFacility = facilityOption
      ?.filter((ele) => ele.checked)
      ?.map((ele) => {
        return { facilityKind: ele.value, planAmount: ele.amount || 0 };
      });

    await mutateAddSite(
      {
        ...formOrigin,
        facilityKindList: [...checkedFacility],
        constructionInfo: {
          owner: {
            ...formOwner,
          },
          contractor: {
            ...formCont,
          },
          subContractor: {
            ...formSubCont,
          },
        },
      },
      {
        onSuccess: () => {
          setSubmitSuccess(true);
          setConfirmOneBtn((prev) => ({ ...prev, desc: '사업장 등록이 완료되었습니다.', isOpen: true }));
          myInfo.refetch();
        },
        onError(error: any, variables, context) {
          setSubmitSuccess(false);
          const errMsg = error?.data?.errorMsg;
          setConfirmOneBtn((prev) => ({ ...prev, desc: errMsg, isOpen: true }));
        },
      },
    );
  };

  const closeConfirm = () => {
    setConfirmOneBtn((prev) => ({ ...prev, isOpen: false }));
    submitSuccess && navigate('../list');
  };

  // facility 체크박스 이벤트
  const onChangeCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setFacilityOption((prev) => {
      return [...prev].map((ele) => (ele.value === name ? { ...ele, checked: checked } : ele));
    });
  };
  const onTabClick = (index) => {
    const newArr = [...step];
    newArr.map((i, idx) => {
      i.pass = index >= idx;
    });
    setStep(newArr);
  };

  return (
    <>
      <ManageContent
        title={t('사업장등록')}
        step={step}
        listPath={'/workplace/list'}
        onTabClick={onTabClick}
        naviRight={
          passArr[passArr.length - 1].num === 4 ? (
            <SurveyHeaderSt.Button type={'button'} style={{ width: 80, height: 40, marginLeft: 10 }} onClick={serverAddSite}>
              {t('등록하기')}
            </SurveyHeaderSt.Button>
          ) : null
        }
        content={
          <>
            {/*//4번탭*/}
            <div style={{ display: passArr[passArr.length - 1].num === 4 ? 'block' : 'none' }}>
              <LabelInput name="companyName" label={t('용역사명')} placeholder={t("용역사명을입력하세요")} onChange={onChangeSubCont} value={formSubCont.companyName} width={400} style={{ marginBottom: 12 }} />
              <LabelInput name="managerName" label={t('담당자')} placeholder={t("담당자를입력하세요")} onChange={onChangeSubCont} value={formSubCont.managerName} width={400} style={{ marginBottom: 12 }} />
              <LabelInput name="email" label={t('이메일')} placeholder={t("이메일을입력하세요")} onChange={onChangeSubCont} value={formSubCont.email} width={400} style={{ marginBottom: 12 }} />
              <LabelInput name="officeNumber" label={t('사무실연락처')} placeholder={t("사무실연락처를입력하세요")} onChange={onChangeSubCont} value={formSubCont.officeNumber} width={400} style={{ marginBottom: 12 }} />
              <LabelInput name="phoneNumber" label={t('휴대폰번호')} placeholder={t("휴대폰번호를입력하세요")} onChange={onChangeSubCont} value={formSubCont.phoneNumber} width={400} />
            </div>

            {/*//3번탭*/}
            <div style={{ display: passArr[passArr.length - 1].num === 3 ? 'block' : 'none' }}>
              <LabelInput name="companyName" label={t('시공사명')} placeholder={t("시공사명을입력하세요")} onChange={onChangeCont} value={formCont.companyName} width={400} style={{ marginBottom: 12 }} />
              <LabelInput name="managerName" label={t('담당자')} placeholder={t("담당자를입력하세요")} onChange={onChangeCont} value={formCont.managerName} width={400} style={{ marginBottom: 12 }} />
              <LabelInput name="email" label={t('이메일')} placeholder={t("이메일을입력하세요")} onChange={onChangeCont} value={formCont.email} width={400} style={{ marginBottom: 12 }} />
              <LabelInput name="officeNumber" label={t('사무실연락처')} placeholder={t("사무실연락처를입력하세요")} onChange={onChangeCont} value={formCont.officeNumber} width={400} style={{ marginBottom: 12 }} />
              <LabelInput name="phoneNumber" label={t('휴대폰번호')} placeholder={t("휴대폰번호를입력하세요")} onChange={onChangeCont} value={formCont.phoneNumber} width={400} />
              <div style={{ textAlign: 'center' }}>
                <DataFilterStyle.BtnSearch
                  type={'button'}
                  style={{ margin: '0 auto' }}
                  onClick={() => {
                    const newArr = [...step];
                    newArr.filter(({ num }) => num === 4)[0].pass = true;
                    setStep(newArr);
                  }}
                >
                  {t('다음단계')}
                  <img
                    src={
                      !(
                        formOrigin.siteName &&
                        formOrigin.projection &&
                        formOrigin.projectName &&
                        formOrigin.address &&
                        formOrigin.observer &&
                        formOrigin.constructionStartDate &&
                        formOrigin.constructionEndDate &&
                        facilityOption.filter(({ checked }) => checked).length
                      )
                        ? IcoNextLight
                        : IcoNext
                    }
                    style={{ marginLeft: 4 }}
                    alt={t('다음')}
                  />
                </DataFilterStyle.BtnSearch>
              </div>
            </div>

            {/*//2번탭*/}
            <div style={{ display: passArr[passArr.length - 1].num === 2 ? 'block' : 'none' }}>
              <LabelInput name="companyName" label={t('발주처명')} placeholder={t("발주처명을입력하세요")} onChange={onChangeOwner} value={formOwner.companyName} width={400} style={{ marginBottom: 12 }} />
              <LabelInput name="managerName" label={t('담당자')} placeholder={t("담당자를입력하세요")} onChange={onChangeOwner} value={formOwner.managerName} width={400} style={{ marginBottom: 12 }} />
              <LabelInput name="email" label={t('이메일')} placeholder={t("이메일을입력하세요")} onChange={onChangeOwner} value={formOwner.email} width={400} style={{ marginBottom: 12 }} />
              <LabelInput name="officeNumber" label={t('사무실연락처')} placeholder={t("사무실연락처를입력하세요")} onChange={onChangeOwner} value={formOwner.officeNumber} width={400} style={{ marginBottom: 12 }} />
              <LabelInput name="phoneNumber" label={t('휴대폰번호')} placeholder={t("휴대폰번호를입력하세요")} onChange={onChangeOwner} value={formOwner.phoneNumber} width={400} />
              <div style={{ textAlign: 'center' }}>
                <DataFilterStyle.BtnSearch
                  type={'button'}
                  style={{ margin: '0 auto' }}
                  onClick={() => {
                    const newArr = [...step];
                    newArr.filter(({ num }) => num === 3)[0].pass = true;
                    setStep(newArr);
                  }}
                >
                  {t('다음단계')}
                  <img
                    src={
                      !(
                        formOrigin.siteName &&
                        formOrigin.projection &&
                        formOrigin.projectName &&
                        formOrigin.address &&
                        formOrigin.observer &&
                        formOrigin.constructionStartDate &&
                        formOrigin.constructionEndDate &&
                        facilityOption.filter(({ checked }) => checked).length
                      )
                        ? IcoNextLight
                        : IcoNext
                    }
                    style={{ marginLeft: 4 }}
                    alt={t('다음')}
                  />
                </DataFilterStyle.BtnSearch>
              </div>
            </div>

            {/*//1번탭*/}
            <div style={{ display: passArr[passArr.length - 1].num === 1 ? 'block' : 'none' }}>
              <LabelInput name="siteName" label={t('사업장명')} placeholder={t("사업장명을입력하세요")} onChange={onChangeOrigin} value={formOrigin?.siteName} width={400} style={{ marginBottom: 12 }} required={true} />
              {/*<LabelInput name="projection" label="좌표계" placeholder={'EPSG:5187'} onChange={onChangeOrigin} value={formOrigin?.projection} width={400} style={{ marginBottom: 12 }} required={true} />*/}
              <LabelForm.Wrap style={{ marginBottom: 12 }}>
                <LabelForm.Label required={true}>{t('좌표계')}</LabelForm.Label>
                <Select
                  option={epsgList}
                  name={'projection'}
                  onChange={(e) => {
                    setFormOrigin({ ...formOrigin, projection: (e.target as any).ariaValueText });
                    // onChangeOrigin(e);
                    const newArr = [...epsgList];
                    newArr.map((i) => {
                      i.active = (e.target as any).ariaValueText === i.value;
                    });
                    setEpsgList(newArr);
                  }}
                  value={epsgList.filter(({ active }) => active)[0].text}
                  width={400}
                />
              </LabelForm.Wrap>
              <LabelInput name="projectName" label={t('사업명')} placeholder={t('사업명을입력하세요')} onChange={onChangeOrigin} value={formOrigin?.projectName} width={400} style={{ marginBottom: 12 }} required={true} />
              <LabelInput name="address" label={t('주소')} placeholder={t('주소를입력하세요')} onChange={onChangeOrigin} value={formOrigin?.address} width={400} style={{ marginBottom: 12 }} required={true} />
              <LabelInput name="observer" label={t('담당자')} placeholder={t('담당자를입력하세요')} onChange={onChangeOrigin} value={formOrigin?.observer} width={400} style={{ marginBottom: 12 }} required={true} />
              <LabelForm.Wrap style={{ marginBottom: 12 }}>
                <LabelForm.Label required={true} htmlFor={'constructionStartDate'}>
                  {t('시공시작일')}
                </LabelForm.Label>
                <DataFilterStyle.Group className={themeMode === 'light' ? 'light' : undefined}>
                  <DatePicker
                    placeholder={t('기간선택')}
                    id={'constructionStartDate'}
                    value={formOrigin?.constructionStartDate}
                    months={monthsKo}
                    headerOrder={['LEFT_BUTTON', 'YEAR_MONTH', 'RIGHT_BUTTON']}
                    monthYearSeparator=" "
                    weekDays={['S', 'M', 'T', 'W', 'T', 'F', 'S']}
                    onChange={(e) => onChangeDate(e, 'constructionStartDate')}
                    maxDate={formOrigin.constructionEndDate}
                    style={{ width: 172 }}
                    className={themeMode === 'light' ? 'light' : undefined}
                  />
                </DataFilterStyle.Group>
              </LabelForm.Wrap>
              <LabelForm.Wrap style={{ marginBottom: 12 }}>
                <LabelForm.Label required={true} htmlFor={'constructionStartDate'}>
                  {t('시공종료일')}
                </LabelForm.Label>

                <DataFilterStyle.Group className={themeMode === 'light' ? 'light' : undefined}>
                  <DatePicker
                    placeholder={t('기간선택')}
                    id={'constructionEndDate'}
                    value={formOrigin?.constructionEndDate}
                    months={monthsKo}
                    headerOrder={['LEFT_BUTTON', 'YEAR_MONTH', 'RIGHT_BUTTON']}
                    monthYearSeparator=" "
                    weekDays={['S', 'M', 'T', 'W', 'T', 'F', 'S']}
                    onChange={(e) => onChangeDate(e, 'constructionEndDate')}
                    minDate={formOrigin.constructionStartDate}
                    style={{ width: 172 }}
                  />
                </DataFilterStyle.Group>
              </LabelForm.Wrap>

              <Dash />
              <LabelForm.Wrap style={{ alignItems: 'flex-start' }}>
                <LabelForm.Label required={true}>{t('관종')}</LabelForm.Label>
                <div>
                  <p style={{ color: '#0076FF', fontSize: 14, fontWeight: 500, display: 'block', marginBottom: 16 }}>※ {t('작업할관종을1개이상선택하고,설계량을입력하세요')}</p>
                  <WorkPlaceStyle.FacilityArea>
                    <WorkPlaceStyle.FacilityColumn>
                      {facilityOption?.map((ele, idx) => {
                        if (idx < 4) {
                          return (
                            <WorkPlaceStyle.FacilityItem key={ele.value}>
                              <WorkPlaceStyle.FacilityCheckArea>
                                <Checkbox
                                  // type="radio"
                                  id={ele?.value}
                                  name={ele?.value}
                                  // text={ele?.text}
                                  // dark={true}
                                  size={18}
                                  checked={ele?.checked}
                                  onChange={onChangeCheck}
                                />
                              </WorkPlaceStyle.FacilityCheckArea>
                              <LabelForm.Wrap>
                                <span
                                  style={{
                                    width: 12,
                                    height: 12,
                                    boxSizing: 'border-box',
                                    borderRadius: 2,
                                    border: `2px solid ${ele.color}`,
                                    margin: '0 10px 0 16px',
                                  }}
                                ></span>
                                <LabelForm.Label labelSize={16} style={{ width: 92, minWidth: 'auto' }}>
                                  {t(ele?.text)}
                                </LabelForm.Label>
                                <Input
                                  name={ele?.value}
                                  type={'number'}
                                  width={180}
                                  height={40}
                                  disabled={!ele?.checked}
                                  onChange={(e) => {
                                    setFacilityOption((prev) => {
                                      return [...prev].map((ele) =>
                                        ele.checked && ele.value === e.target.name
                                          ? {
                                              ...ele,
                                              amount: Number(e.target.value),
                                            }
                                          : ele,
                                      );
                                    });
                                  }}
                                  // value={ele.amount}
                                  placeholder={t('설계량입력')}
                                />
                              </LabelForm.Wrap>
                            </WorkPlaceStyle.FacilityItem>
                          );
                        }
                      })}
                    </WorkPlaceStyle.FacilityColumn>
                    <WorkPlaceStyle.FacilityColumn>
                      {facilityOption?.map((ele, idx) => {
                        if (idx > 3) {
                          return (
                            <WorkPlaceStyle.FacilityItem key={ele.value}>
                              <WorkPlaceStyle.FacilityCheckArea>
                                <Checkbox
                                  // type="radio"
                                  id={ele?.value}
                                  name={ele?.value}
                                  // text={ele?.text}
                                  // dark={true}
                                  size={18}
                                  checked={ele?.checked}
                                  onChange={onChangeCheck}
                                />
                              </WorkPlaceStyle.FacilityCheckArea>
                              <LabelForm.Wrap>
                                <span style={{ width: 12, height: 12, boxSizing: 'border-box', borderRadius: 2, border: `2px solid ${ele.color}`, margin: '0 10px 0 16px' }}></span>
                                <LabelForm.Label labelSize={16} style={{ width: 92, minWidth: 'auto' }}>
                                  {t(ele?.text)}
                                </LabelForm.Label>
                                <Input
                                  name={ele?.value}
                                  type={'number'}
                                  width={180}
                                  height={40}
                                  disabled={!ele?.checked}
                                  onChange={(e) => {
                                    setFacilityOption((prev) => {
                                      return [...prev].map((ele) =>
                                        ele.checked && ele.value === e.target.name
                                          ? {
                                              ...ele,
                                              amount: Number(e.target.value),
                                            }
                                          : ele,
                                      );
                                    });
                                  }}
                                  placeholder={t('설계량입력')}
                                />
                              </LabelForm.Wrap>
                            </WorkPlaceStyle.FacilityItem>
                          );
                        }
                      })}
                    </WorkPlaceStyle.FacilityColumn>
                  </WorkPlaceStyle.FacilityArea>
                </div>
              </LabelForm.Wrap>
              <Solid />
              <div style={{ textAlign: 'center' }}>
                <DataFilterStyle.BtnSearch
                  type={'button'}
                  disabled={
                    !(
                      formOrigin.siteName &&
                      formOrigin.projection &&
                      formOrigin.projectName &&
                      formOrigin.address &&
                      formOrigin.observer &&
                      formOrigin.constructionStartDate &&
                      formOrigin.constructionEndDate &&
                      facilityOption.filter(({ checked }) => checked).length
                    )
                  }
                  style={{ margin: '0 auto' }}
                  onClick={() => {
                    const newArr = [...step];
                    newArr.filter(({ num }) => num === 2)[0].pass = true;
                    setStep(newArr);
                  }}
                >
                  {t('다음단계')}
                  <img
                    src={
                      !(
                        formOrigin.siteName &&
                        formOrigin.projection &&
                        formOrigin.projectName &&
                        formOrigin.address &&
                        formOrigin.observer &&
                        formOrigin.constructionStartDate &&
                        formOrigin.constructionEndDate &&
                        facilityOption.filter(({ checked }) => checked).length
                      )
                        ? IcoNextLight
                        : IcoNext
                    }
                    style={{ marginLeft: 4 }}
                    alt={t('다음')}
                  />
                </DataFilterStyle.BtnSearch>
              </div>
            </div>
          </>
        }
      />
      {confirmOneBtnState.isOpen && <ConfirmOneBtn desc={confirmOneBtnState.desc || ''} onClickCancel={closeConfirm} onClickSubmit={() => {}} />}
    </>
  );
};

export default WorkPlaceRegister;
