import { useQuery } from '@tanstack/react-query';
import { deletePinImage, getHoles, getMapPins, getPins, getPipes, uploadPinImage, uploadPins } from '../../service/smartpin';
import queryKeys from '../../keys/queryKeys';
import useMutateHook from '../../useMutateHook';

//지도용 스마트핀 호출
export const useMapPins = ({ siteId }) => {
  return useQuery({
    queryKey: [[...queryKeys.smartpinKeys.pin({ siteId })]],
    queryFn: () => getMapPins({ siteId }),
    enabled: !!siteId,
  });
};

//스마트핀 리스트 호출
export const usePins = ({ siteId, pageSize, pageNum, sort }) => {
  return useQuery({
    queryKey: [[...queryKeys.smartpinKeys.pin({ siteId, pageSize, pageNum, sort })]],
    queryFn: () => getPins({ siteId, pageSize, pageNum, sort }),
  });
};

//스마트핀 이미지 업로드(단일)
export const useUploadPinImage = ({ siteId, serial, image }) => {
  return useMutateHook({
    invalidQuery: [[[...queryKeys.smartpinKeys.pin({ siteId })]]],
    fetchApi: () => uploadPinImage({ siteId, serial, image }),
  });
};

//스마트핀 이미지 삭제(단일)
export const useDeletePinImage = ({ siteId, serial }) => {
  return useMutateHook({
    invalidQuery: [[[...queryKeys.smartpinKeys.pin({ siteId })]]],
    fetchApi: () => deletePinImage({ siteId, serial }),
  });
};

export const useUploadPins = ({ siteId  }) => {
  return useMutateHook({
    invalidQuery: [[[...queryKeys.smartpinKeys.all]]],
    fetchApi: ({csvFile}) => uploadPins({ siteId, csvFile }),
  });
};
