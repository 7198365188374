import styled from 'styled-components';
import arrow from 'assets/images/2dMap/ico-arrow-left.svg';
import arrowDark from 'assets/images/2dMap/ico-arrow-left-dark.svg';
import arrowA from 'assets/images/2dMap/ico-arrow-left-active.svg';
import bgShadow from 'assets/images/2dMap/bg-shadow.svg';
import bgShadowDark from 'assets/images/2dMap/bg-shadow-dark.svg';
import IcoStartPoint from 'assets/images/2dMap/ico-start-point.svg';
import IcoEndPoint from 'assets/images/2dMap/ico-end-point.svg';
import IcoView from 'assets/images/2dMap/ico-view.svg';
import IcoDelete from 'assets/images/2dMap/ico-delete.svg';
import IcoAddImg from 'assets/images/2dMap/ico-add-img.svg';
import IcoAddImgA from 'assets/images/2dMap/ico-add-img-active.svg';
import NoImg from 'assets/images/no-img.svg';
import IcoClose from 'assets/images/ico-pop-close-light.svg';
import IcoInfo from 'assets/images/2dMap/ico-info.svg';
import IcoUpload from 'assets/images/2dMap/ico-upload.svg';
import Button from 'components/atoms/Button';
import IcoEdit from 'assets/images/2dMap/ico-edit.svg';
import IcoEditA from 'assets/images/2dMap/ico-edit-active.svg';
import IcoSelect from 'assets/images/2dMap/ico-select.svg';
import IcoJoin from 'assets/images/2dMap/ico-join.svg';
import IcoShortCut from 'assets/images/2dMap/ico-shortcut.svg';

// 지도 설정 버튼
export const SettingBtnSt = {
  Button: styled.button`
    width: fit-content;
    background-color: #fff;
    border: 1px solid #439aff;
    border-radius: 2px;
    color: #0076ff;
    font-weight: 600;
    font-size: 14px;
    transition: 0.4s;
    height: 36px;
    box-sizing: border-box;
    padding: 0 16px;
    margin-right: 6px;
    &:hover {
      background-color: #e7f1fd;
    }

    &:active {
      background-color: #c0d7f3;
    }
  `,
};

// 편집 버튼 스타일
export const EditBtnSt = {
  Wrap: styled.div``,
  Button: styled.button`
    width: fit-content;
    background-color: #fff;
    border: 1px solid #439aff;
    border-radius: 2px;
    color: #0076ff;
    font-weight: 600;
    font-size: 14px;
    transition: 0.4s;
    height: 36px;
    box-sizing: border-box;
    padding: 0 10px;
    margin-right: 6px;
    display: flex;
    align-items: center;
    gap: 6px;

    &:hover {
      background-color: #e7f1fd;
    }
    &:active {
      background-color: #c0d7f3;
    }
    // 아이콘
    &::before {
      content: '';
      display: inline-block;
      width: 24px;
      height: 24px;
      background: url(${IcoEdit}) no-repeat center;
    }
    &.active {
      position: relative;
      background-color: #4880ff;
      color: #fff;
      &::before {
        background-image: url(${IcoEditA});
      }
      // 하단 그림자
      &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -12px;
        width: 74px;
        height: 12px;
        background: linear-gradient(180deg, rgba(72, 128, 255, 0.18) 0%, rgba(72, 128, 255, 0) 100%);
      }
    }
  `,
  ListWrap: styled.div`
    width: 76px;
    height: 0;
    visibility: hidden;
    background-color: #fff;
    border: 1px solid #cccccc80;
    border-radius: 0 0 2px 2px;
    box-sizing: border-box;
    transition: all 0.2s ease-in;
    overflow: hidden;
    ul {
      opacity: 0;
    }
    &.active {
      transition: all 0.2s ease-in;
      height: 142px;
      visibility: visible;
      overflow: visible;
      ul {
        transition: all 0.4s ease-in;
        opacity: 1;
      }
    }
  `,
  List: styled.ul`
    padding: 6px 8px;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    gap: 8px;
  `,
  Item: styled.li`
    button {
      position: relative;
      width: 60px;
      height: 32px;
      overflow: visible;
      display: flex;
      align-items: center;
      justify-content: center;

      &.active {
        background-color: #f1f1f1;
        .ico {
          filter: none; // 검정
        }
      }
      .ico {
        display: inline-block;
        width: 24px;
        height: 24px;
        background-repeat: no-repeat;
        background-position: center;
        filter: invert(68%) sepia(0%) saturate(759%) hue-rotate(303deg) brightness(97%) contrast(91%); // disabled
        &.select {
          background-image: url(${IcoSelect});
        }
        &.join {
          background-image: url(${IcoJoin});
        }
        &.shotcut {
          background-image: url(${IcoShortCut});
        }
      }
      .tooltip {
        display: inline-block;
        width: max-content;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 60px;
        opacity: 0;
        visibility: hidden;
        padding: 9px 6px;
        background-color: rgba(0, 0, 0, 0.7);
        border-radius: 4px;
        box-shadow: 3px 3px 10px 0px #00000037;
        color: #fff;
        // 삼각형
        &::after {
          content: ' ';
          position: absolute;
          z-index: 1;
          top: 50%;
          right: calc(100%);
          margin-top: -5px;
          border-width: 5px;
          border-style: solid;
          border-color: transparent rgba(0, 0, 0, 0.7) transparent transparent;
        }
      }
      &:hover {
        transition: all 0.4s;
        background-color: #f1f1f1;
        .tooltip {
          transition: all 0.4s;
          left: 80px;
          opacity: 1;
          visibility: visible;
        }
      }
    }
  `,
};

// 지도 설정 영역
export const SettingWrapSt = {
  Wrap: styled.div`
    position: absolute;
    left: -239px;
    z-index: 9;
    width: 252px;
    width: 240px;
    height: 100%;
    border-right: 1px solid #dfdfdf;
    border-top: none;
    box-sizing: border-box;
    color: ${(p) => p.theme.map.setting.color};
    border-color: ${(p) => p.theme.map.setting.border};
    background-color: ${(p) => p.theme.map.setting.bg};
    background-image: ${(p) => `url(${p.theme.map.setting.bgImg})`};
    background-repeat: no-repeat;
    background-size: cover;
    transition: all 0.4s;
    &.isOpen {
      left: 0px;
    }
  `,
  CloseBtn: styled.button`
    position: absolute;
    top: 50%;
    right: -20px;
    transform: translate(0, -50%);
    width: 20px;
    height: 40px;
    border-radius: 0 2px 2px 0;
    border-left: 1px solid #dfdfdf;
    border-color: ${(p) => p.theme.map.setting.closeBtn.border};
    background-color: ${(p) => p.theme.map.setting.closeBtn.bg};
    display: flex;
    align-items: center;
    justify-content: center;
    &::after {
      content: '';
      width: 4px;
      height: 6px;
      transform: rotate(180deg);
      background-image: ${(p) => `url(${p.theme.map.setting.closeBtn.ico})`};
      background-size: cover;
      transition: all 0.4s;
    }
    &:hover {
      &::after {
        background-image: ${(p) => `url(${p.theme.map.setting.closeBtn.icoA})`};
      }
    }
    &:active {
      background-color: ${(p) => p.theme.map.setting.closeBtn.bgA};
    }
    &.isOpen {
      &::after {
        transform: rotate(0);
      }
    }
  `,
  SiteWrap: styled.div``,
  SiteHead: styled.div`
    height: 48px;
    padding: 0 20px;
    box-sizing: border-box;
    border-bottom: 1px solid #313d4f;
    display: flex;
    align-items: center;
  `,
  SiteTitle: styled.strong`
    font-size: 16px;
    font-weight: 600;
    color: #fff;
  `,
  SiteCount: styled.span`
    margin-left: 8px;
    font-size: 14px;
    color: #4880ff;
    font-weight: 600;
  `,
  SiteCont: styled.div`
    padding: 12px 0;
    border-bottom: 1px solid #313d4f;
    margin-bottom: 12px;
  `,
  SiteList: styled.div`
    max-height: 146px;
    padding: 0 20px;
  `,
  SiteItem: styled.div`
    height: 40px;
    border-radius: 2px;
    background: #323d4e;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    p {
      color: #fff;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: 600;
      font-size: 14px;
      flex: 1;
    }
    &:not(:last-child) {
      margin-bottom: 2px;
    }
  `,
  TabWrap: styled.div`
    border-radius: 4px;
    background-color: ${(p) => p.theme.map.setting.tab.bg};
    padding: 4px;
    margin: 0 20px 8px;
    display: flex;
    gap: 4px;
    button {
      width: 96px;
      height: 32px;
      border-radius: 4px;
      color: ${(p) => p.theme.map.setting.tab.color};
      font-weight: 600;
      font-size: 14px;
      transition: all 0.4s;
      &.active {
        color: #0076ff;
        background-color: ${(p) => p.theme.map.setting.tab.bgA};
      }
      &:hover {
        color: #0076ff;
      }
    }
  `,
  ContWrap: styled.div`
    margin: 0 20px;
    border: 1px solid #313d4f;
    border-radius: 4px;
  `,
  ContHead: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #313d4f;
    height: 40px;
    box-sizing: border-box;
    padding: 0 16px;
  `,
  ContTitle: styled.strong`
    color: #fff;
    font-size: 14px;
    font-weight: 600;
  `,
  LabelCheckWrap: styled.div`
    padding: 12px 0;
  `,
  LabelCheckList: styled.div`
    max-height: 108px;
    padding: 0 16px;
    > div {
      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  `,
  EtcWrap: styled.div`
    padding: 0 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;      
    color:${p => p.theme.map.infoModal.color};
    font-weight: 600;
  `,
  EtcToggle: styled.button`
    width: 38px;
    height: 20px;
    border-radius: 4px;
    background: #464646;
    position: relative;
    &:after {
      content: '';
      width: 16px;
      height: 16px;
      border-radius: 4px;
      position: absolute;
      left: 2px;
      top: 2px;
      transition: 0.4s;
      background: #fff;
    }
    &.active {
      &:after {
        left: calc(100% - 18px);
      }
    }
  `,
  JobIdWrap: styled.div`
    background-color: ${(p) => p.theme.map.setting.jobId.bg};
    > .header {
      padding: 8px 16px;
      border-bottom: 1px solid ${(p) => p.theme.map.setting.jobId.border};
    }
    > .list {
      padding: 12px 16px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      li {
        line-height: 18px;
        font-weight: 500;
        font-size: 14px;
      }
    }
  `,
  Divider: styled.div`
    width: 100%;
    height: 1px;
    margin-top: 19px;
    margin-bottom: 21px;
    background-color: ${(p) => p.theme.map.setting.divider.bg};
  `,
};

// 상세 정보창 공통 스타일 - 리뉴얼
export const PipeInfoModalSt = {
  Wrap: styled.div`
    position: absolute;
    right: 60px;
    bottom: 20px;
    z-index: 9;
    border-radius: 4px 0 4px 4px;
    color: ${(p) => p.theme.map.infoModal.color};
  `,
  CloseBtn: styled.div`
    width: 36px;
    height: 36px;
    opacity: 0.88;
    border-radius: 4px 4px 0 0;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .icoClose {
      transition: 0.4s;
      filter: ${(p) => p.theme.map.infoModal.closeBtn};
    }
    &:hover,
    &:active {
      .icoClose {
        filter: ${(p) => p.theme.map.infoModal.closeBtnHover};
      }
    }
    &:active {
      background-color: ${(p) => p.theme.map.infoModal.closeBtnBg};
    }
  `,
  Header: styled.div`
    height: 52px;
    background-color: ${(p) => p.theme.map.infoModal.bg};
    padding: 16.5px 20px 16.5px 24px;
    box-sizing: border-box;
    border: 1px solid ${(p) => p.theme.map.infoModal.border};
    border-bottom-color: ${(p) => p.theme.map.infoModal.titleBd};
    border-radius: 4px 4px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > .titleWrap {
      display: flex;
      gap: 10px;
      line-height: 19px;
      > .title {
        padding-right: 10px;
        border-right: 1px solid #7b7b7b;
        font-weight: 600;
        font-size: 16px;
      }
      span {
        font-weight: 400;
        font-size: 13px;
      }
    }
  `,
  Cont: styled.div`
    border: 1px solid ${(p) => p.theme.map.infoModal.border};
    border-top: 0;
    border-radius: 0 0 4px 4px;
  `,
  InfoWrap: styled.div`
    background-color: ${(p) => p.theme.map.infoModal.bg};
  `,
  MainInfoWrap: styled.div`
    width: 380px;
  `,
  MainInfo: styled.div`
    padding: 12px 0;
    margin: 0 24px;
    border-bottom: 1px solid ${(p) => p.theme.map.infoModal.titleBd};
    > .titleWrap {
      padding: 8px 13px;
      display: flex;
      justify-content: space-between;
      border: 1px solid ${(p) => p.theme.map.infoModal.titleBd};
      border-radius: 2px;
      .title {
        font-weight: 600;
        font-size: 14px;
        display: flex;
        align-items: center;
        gap: 6px;
        > .ico {
          display: inline-block;
          width: 14px;
          height: 14px;
          background: url(${IcoStartPoint}) no-repeat center;
        }
      }
      > .titleCont {
        font-weight: 600;
        font-size: 13px;
      }
    }
    > .cont {
      margin-left: 3px;
      margin-top: 11px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      > .item {
        width: 135px;
        display: flex;
        justify-content: space-between;
        p {
          font-weight: 600;
          font-size: 14px;
        }
        > span {
          font-weight: 400;
          font-size: 13px;
        }
        &:nth-child(n + 3) {
          margin-top: 6px;
        }
      }
    }
    &.endPt {
      .title {
        .ico {
          width: 18.29px;
          height: 16px;
          background: url(${IcoEndPoint}) no-repeat center;
        }
      }
    }
  `,
  SubInfoWrap: styled.div`
    padding: 12px 0 20px 0;
    margin: 0 24px;
    > .btnWrap {
      margin-top: 20px;
      margin-bottom: 4px;
      display: flex;
      justify-content: space-between;
      gap: 12px;
      button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 1 fit-content;
        height: 36px;
        border-radius: 2px;
        border: 1px solid ${(p) => p.theme.map.infoModal.btnBorder};
        background-color: ${(p) => p.theme.map.infoModal.btnBg};
        color: ${(p) => p.theme.map.infoModal.color};
        font-weight: 500;
        font-size: 14px;
        text-align: left;
        padding: 0 16px;
        box-sizing: border-box;
        &::after {
          content: '';
          width: 18px;
          height: 18px;
          display: inline-block;
          background: ${(p) => `url(${p.theme.map.infoModal.icoMore})`};
        }
        &:hover {
          transition: 0.4s;
          border: 1px solid #0076ff;
          color: #0076ff;
          box-shadow: 0 4px 4px 0 rgba(0, 118, 255, 0.2);
          &::after {
            background-image: ${(p) => `url(${p.theme.map.infoModal.icoMoreA})`};
          }
        }
      }
    }
  `,
  SubInfo: styled.ul`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 6px;
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        font-weight: 600;
        font-size: 14px;
      }
      .cont {
        font-weight: 400;
        font-size: 13px;
      }
    }
  `,
  ImgWrap: styled.div`
    flex: 1;
    margin-top: 10px;
    height: 100%;
    opacity: 0.88;
    border-radius: 0 0 2px 0;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    justify-content: flex-start;
    gap: 6px;
  `,
  ImgItem: styled.div`
    width: 80px;
    height: 60px;
    border: 1px solid #111;
    border-radius: 4px;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    background-color: #b2b5c0;
    /* background: rgb(204, 208, 222) url(${NoImg}) no-repeat center; */
    > img {
      /* width: 100%; */
      width: auto;
      height: 100%;
    }
    > .addImg {
      width: 100%;
      height: 100%;
      background-color: #b2b5c0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      > .ico {
        width: 24px;
        height: 24px;
        display: inline-block;
        background: url(${IcoAddImg}) no-repeat center;
        transition: all.4s;
      }
      &:hover {
        > .ico {
          /* background-image: url(${IcoAddImgA}); */
          /* filter: ${(p) => p.theme.map.infoModal.closeBtnHover}; */
        }
      }
    }
    > .hoverText {
      display: none;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6);
      border: 2px solid #0076ff;
      box-sizing: border-box;
      .textWrap {
        width: 100%;
        position: relative;
        p.text {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
          height: 22px;
          /* line-height: 22px; */
          font-weight: 500;
          font-size: 12px;
          text-align: center;
          color: #fff;
          background-color: #0076ff;
          cursor: pointer;
        }
        span.tooltip-content {
          display: none;
          width: fit-content;
          position: fixed;
          z-index: 99999;
          color: #fafafa;
          background-color: #2a2a2a;
          padding: 2px 8px;
          border-radius: 2px;
          cursor: default;
        }
        /* &:hover {
          span.tooltip-content {
            display: block;
          }
        } */
      }

      > .btnWrap {
        display: flex;
        gap: 4px;
        margin-bottom: 4px;
        button {
          cursor: pointer;
          &.icoView {
            width: 24px;
            height: 24px;
            background: url(${IcoView}) no-repeat center;
          }
          &.icoDelete {
            width: 24px;
            height: 24px;
            background: url(${IcoDelete}) no-repeat center;
          }
          &.icoAdd {
            width: 24px;
            height: 24px;
            background: url(${IcoAddImg}) no-repeat center;
          }
          &:hover {
            &.icoView,
            &.icoDelete,
            &.icoAdd {
              filter: ${(p) => p.theme.map.infoModal.closeBtnHover};
            }
          }
        }
      }
    }
    &:hover {
      > .hoverText {
        display: flex;
      }
      > .addImg {
        > .ico {
          display: none;
        }
      }
    }
  `,
};

// 상세 정보창 공통 스타일
export const PipeInfoModalSt_old = {
  Wrap: styled.div`
    position: absolute;
    right: 40px;
    bottom: 20px;
    z-index: 9;
    /* width: 500px; */
    /* min-height: 332px; */
    border-radius: 4px 0 4px 4px;
    color: ${(p) => p.theme.map.infoModal.color};
  `,
  CloseBtn: styled.button`
    position: absolute;
    top: -36px;
    right: 0;
    width: 36px;
    height: 36px;
    background-color: ${(p) => p.theme.map.infoModal.bg};
    opacity: 0.88;
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid ${(p) => p.theme.map.infoModal.border};
    box-sizing: border-box;
    .icoClose {
      transition: 0.4s;
      filter: ${(p) => p.theme.map.infoModal.closeBtn};
    }
    &:hover,
    &:active {
      .icoClose {
        filter: ${(p) => p.theme.map.infoModal.closeBtnHover};
      }
    }
    &:active {
      background-color: ${(p) => p.theme.map.infoModal.closeBtnBg};
    }
  `,
  Header: styled.div`
    height: 52px;
    background-color: ${(p) => p.theme.map.infoModal.bg};
    padding: 16.5px 20px 16.5px 24px;
    box-sizing: border-box;
    border: 1px solid ${(p) => p.theme.map.infoModal.border};
    border-bottom: 0;
    /* border-bottom: 1px solid ${(p) => p.theme.map.infoModal.border}; */
    border-radius: 4px 0 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > .title {
      line-height: 19px;
      font-weight: 600;
      font-size: 16px;
    }
    span {
      font-weight: 400;
      font-size: 13px;
    }
  `,
  Cont: styled.div`
    display: flex;
    min-height: 210px;
    border-radius: 0 0 1px 4px;
    border: 1px solid ${(p) => p.theme.map.infoModal.border};
  `,
  InfoWrap: styled.div`
    flex: 4;
    background-color: ${(p) => p.theme.map.infoModal.bg};
    display: flex;
    /* height: 100%; */
  `,
  MainInfoWrap: styled.div`
    width: 374px;
  `,

  MainInfo: styled.ul`
    width: 100%;
    padding: 16px 24px 12px 24px;
    box-sizing: border-box;
    &.divider {
      border-bottom: 1px solid ${(p) => p.theme.map.infoModal.border};
    }
    > .titleWrap {
      margin-bottom: 13px;
      display: flex;
      justify-content: space-between;
      .title {
        font-weight: 600;
        font-size: 14px;
        display: flex;
        align-items: center;
        gap: 6px;
        > .ico {
          display: inline-block;
          width: 14px;
          height: 14px;
          background: url(${IcoStartPoint}) no-repeat center;
        }
      }
      > .titleCont {
        font-weight: 600;
        font-size: 13px;
      }
    }
    > .cont {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      > .item {
        width: 135px;
        display: flex;
        justify-content: space-between;
        p {
          font-weight: 600;
          font-size: 14px;
        }
        > span {
          font-weight: 400;
          font-size: 13px;
        }
        &:nth-child(n + 3) {
          margin-top: 6px;
        }
      }
    }
    &.endPt {
      .title {
        .ico {
          width: 18.29px;
          height: 16px;
          background: url(${IcoEndPoint}) no-repeat center;
        }
      }
    }
  `,
  SubInfoWrap: styled.div`
    min-width: 176px;
    padding: 12px 16px 20px 16px;
    box-sizing: border-box;
    border-left: 1px solid ${(p) => p.theme.map.infoModal.border};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    > .btnWrap {
      display: flex;
      flex-direction: column;
      gap: 6px;
      button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 36px;
        border-radius: 2px;
        border: 1px solid ${(p) => p.theme.map.infoModal.btnBorder};
        background-color: ${(p) => p.theme.map.infoModal.btnBg};
        color: ${(p) => p.theme.map.infoModal.color};
        font-weight: 500;
        font-size: 14px;
        text-align: left;
        padding: 0 16px;
        box-sizing: border-box;
        &::after {
          content: '';
          width: 18px;
          height: 18px;
          display: inline-block;
          background: ${(p) => `url(${p.theme.map.infoModal.icoMore})`};
        }
        &:hover {
          transition: 0.4s;
          border: 1px solid #0076ff;
          color: #0076ff;
          box-shadow: 0 4px 4px 0 rgba(0, 118, 255, 0.2);
          &::after {
            background-image: ${(p) => `url(${p.theme.map.infoModal.icoMoreA})`};
          }
        }
      }
    }
  `,
  SubInfo: styled.ul`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 6px;
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        font-weight: 600;
        font-size: 14px;
      }
      .cont {
        font-weight: 400;
        font-size: 13px;
      }
    }
  `,
  ImgWrap: styled.div`
    flex: 1;
    margin-top: 15px;
    height: 100%;
    opacity: 0.88;
    border-radius: 0 0 2px 0;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    justify-content: flex-start;
    gap: 6px;
  `,
  ImgItem: styled.div`
    width: 80px;
    height: 60px;
    border: 1px solid #111;
    border-radius: 4px;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    background-color: #b2b5c0;
    /* background: rgb(204, 208, 222) url(${NoImg}) no-repeat center; */
    > img {
      /* width: 100%; */
      width: auto;
      height: 100%;
    }
    > .addImg {
      width: 100%;
      height: 100%;
      background-color: #b2b5c0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      > .ico {
        width: 24px;
        height: 24px;
        display: inline-block;
        background: url(${IcoAddImg}) no-repeat center;
        transition: all.4s;
      }
      &:hover {
        > .ico {
          /* background-image: url(${IcoAddImgA}); */
          /* filter: ${(p) => p.theme.map.infoModal.closeBtnHover}; */
        }
      }
    }
    > .hoverText {
      display: none;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6);
      border: 2px solid #0076ff;
      box-sizing: border-box;
      p {
        width: 100%;
        line-height: 22px;
        font-weight: 500;
        font-size: 12px;
        text-align: center;
        color: #fff;
        background-color: #0076ff;
      }
      > .btnWrap {
        display: flex;
        gap: 4px;
        margin-bottom: 4px;
        button {
          cursor: pointer;
          &.icoView {
            width: 24px;
            height: 24px;
            background: url(${IcoView}) no-repeat center;
          }
          &.icoDelete {
            width: 24px;
            height: 24px;
            background: url(${IcoDelete}) no-repeat center;
          }
          &.icoAdd {
            width: 24px;
            height: 24px;
            background: url(${IcoAddImg}) no-repeat center;
          }
          &:hover {
            &.icoView,
            &.icoDelete,
            &.icoAdd {
              filter: ${(p) => p.theme.map.infoModal.closeBtnHover};
            }
          }
        }
      }
    }
    &:hover {
      > .hoverText {
        display: flex;
      }
      > .addImg {
        > .ico {
          display: none;
        }
      }
    }
  `,
};

// 이미지 관련 팝업
export const ImgPopupSt = {
  Dimmed: styled.div`
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    background: rgba(0, 0, 0, 0.7);
  `,
  Wrap: styled.div<{ $width?: number }>`
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    /* box-shadow: ${(p) => p.theme.popup.cont.shadow} ; */
    border-radius: 4px;
    /* background: ${(p) => p.theme.popup.cont.bg}; */
    width: ${(p) => (p.$width ? p.$width + 'px' : '1200px')};
  `,
  Header: styled.div`
    height: 48px;
    padding: 0 10px;
    box-sizing: border-box;
    background-color: ${(p) => p.theme.imgModal.head.bg};
    color: ${(p) => p.theme.imgModal.head.color};
    border-radius: 4px 4px 0 0;
    font-weight: 600;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
    }
    .closeBtn {
      width: 42px;
      height: 42px;
      border-radius: 4px;
      position: absolute;
      right: 12px;
      background: ${(p) => `url(${p.theme.imgModal.head.btn}) no-repeat center`};
      &:hover {
        background-color: ${(p) => p.theme.imgModal.head.btnHover};
        transition: 0.4s;
      }
      &:active {
        background-color: ${(p) => p.theme.imgModal.head.btnActive};
      }
    }
  `,
  ContDelete: styled.div`
    padding: 36px 53px;
    background-color: ${(p) => p.theme.imgModal.bg};
    border-top: 1px solid ${(p) => p.theme.imgModal.border};
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    > .mainText {
      color: ${(p) => p.theme.imgModal.head.color};
      font-weight: 600;
      font-size: 16px;
    }
    > .subText {
      color: ${(p) => p.theme.imgModal.infoTextColor};
      font-weight: 500;
      font-size: 14px;
      display: flex;
      align-items: center;
      gap: 6px;
      > .ico {
        width: 24px;
        height: 24px;
        display: inline-block;
        background: ${(p) => `url(${p.theme.imgModal.infoIco}) no-repeat center`};
      }
    }
  `,
  BtnWrapDelete: styled.div`
    height: 40px;
    display: flex;
    border-radius: 0 0 4px 4px;
    overflow: hidden;
    button {
      flex: 1;
      /* color: #fff; */
      color: ${(p) => p.theme.map.infoModal.color};
      font-weight: 500;
      font-size: 14px;
      background-color: #233250;
      background-color: ${(p) => p.theme.imgDelModal.btnBg};
      transition: all.4s;
      &:first-child {
        border-right: 1px solid ${(p) => p.theme.imgModal.border};
      }
      &:hover {
        /* color: #0076ff; */
        color: ${(p) => p.theme.imgDelModal.btnHoverColor};
        background-color: ${(p) => p.theme.imgDelModal.btnHoverBg};
      }
    }
  `,
  ContAdd: styled.div`
    padding: 24px;
    /* box-sizing: border-box; */
    background-color: #1c2431;
    background-color: ${(p) => p.theme.imgModal.bg};
    input {
      display: none;
    }
  `,

  BtnWrapAdd: styled.div`
    background-color: #192e4b;
    background-color: ${(p) => p.theme.imgAddModal.btnWrapBg};
    border-radius: 0 0 4px 4px;
    padding: 19px 24px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > .subText {
      /* color: #a0aec0; */
      color: ${(p) => p.theme.imgModal.infoTextColor};
      font-weight: 400;
      font-size: 12px;
      display: flex;
      align-items: center;
      /* gap: 10px; */
      > .ico {
        margin-right: 10px;
        width: 24px;
        height: 24px;
        display: inline-block;
        /* background: url(${IcoInfo}) no-repeat center; */
        background: ${(p) => `url(${p.theme.imgModal.infoIco}) no-repeat center`};
      }
      > .impact {
        color: ${(p) => p.theme.imgModal.impactColor};
      }
    }
    > .btnWrap {
      display: flex;
      gap: 8px;
      button {
        width: 100px;
        height: 40px;
        /* color: #fff; */
        border: 1px solid ${(p) => p.theme.imgAddModal.btnBdr};
        color: ${(p) => p.theme.map.infoModal.color};
        background-color: ${(p) => p.theme.imgAddModal.btnBg};
        font-weight: 400;
        font-size: 14px;
        transition: all 0.4s;
        &:hover {
          &.delete {
            color: #f01a1a;
            border: 1px solid #f01a1a;
            background-color: rgba(240, 26, 26, 0.15);
          }
          &.add {
            color: #0076ff;
            border: 1px solid #0076ff;
            background-color: rgba(72, 128, 255, 0.15);
          }
        }
      }
    }
  `,
};
