import React from 'react';
import { PopupFrameSt } from './_style';
import { useTranslation } from 'react-i18next';

// header (close)
// content
// button (full, left, right)
// 기본 : 성공 / 실패
// 커스텀용
function PopupFrame({
  acceptText,
  isHelpText,
  title = '파일 업로드',
  children,
  leftText,
  rightText,
  leftFunc,
  rightFunc,
  close,
  disabled,
}: {
  acceptText?: string;
  isHelpText?: boolean;
  title?: string;
  children: React.ReactNode;
  leftText?: string;
  rightText?: string;
  leftFunc?: () => void;
  rightFunc?: () => void;
  close?: () => void;
  disabled?: boolean;
}) {
  const {t} = useTranslation();
  return (
    <>
      <PopupFrameSt.Dimmed></PopupFrameSt.Dimmed>
      <PopupFrameSt.Popup>
        <PopupFrameSt.HeaderWrap>
          <p className="title">{title === '파일 업로드' ? t('파일업로드') : t(title)}</p>
          <button className="closeBtn" onClick={close}></button>
        </PopupFrameSt.HeaderWrap>
        <PopupFrameSt.ContWrap>{children}</PopupFrameSt.ContWrap>
        <PopupFrameSt.Footer className={!rightText ? 'full' : ''}>
          {isHelpText && (
            <div className="helpText">
              <div className="ico"></div>
              <p className="text">
                <span>{acceptText} 파일</span>만 추가 가능합니다.
              </p>
            </div>
          )}
          <PopupFrameSt.BtnWrap className={!rightText ? 'full' : ''}>
            {leftText ? (
              <button onClick={leftFunc} className={`${leftText === '취소' || leftText === '결과 파일 다운로드' ? 'red' : ''}`}>
                {t(leftText)}
              </button>
            ) : null}
            {rightText ? (
              <button onClick={rightFunc ? rightFunc : close} disabled={disabled ? disabled : false}>
                {t(rightText)}
              </button>
            ) : null}
            {!rightText && <button onClick={close}>확인</button>}
          </PopupFrameSt.BtnWrap>
        </PopupFrameSt.Footer>
      </PopupFrameSt.Popup>
    </>
  );
}

export default PopupFrame;
