import React, { useEffect, useState } from 'react';
import { PopupStyle, ReportStyle } from './_style';
import { Swiper, SwiperSlide } from 'swiper/react';
// Survey Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import { Pagination, Navigation, Thumbs, Scrollbar } from 'swiper/modules';
import { PointImagesProps, ReportPhotoInfoProps } from './_interface';
import IcoNoData from '../../assets/images/ico-not-selected.svg';
import { useNavigate } from 'react-router-dom';
import { onOpen3D, pointImgList, unityScheme } from '../../util/global';
import LargeImagePopup from './popup/LargeImagePopup';
import PopupReportImage from './popup/PopupReportImage';
import { useTranslation } from 'react-i18next';
import query from 'hooks/RQuery/utils';

const ReportPhotoInfo = ({ detailInfo, selectType }: ReportPhotoInfoProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [popupState, setPopupState] = useState<boolean>(false);
  const [imgNum, setImgNum] = useState(0);
  const [stImages, setStImages] = useState<PointImagesProps[]>([]);
  const [endImages, setEndImages] = useState<PointImagesProps[]>([]);

  //시점 이미지
  const stImage = query.files.useImages({ pointId: detailInfo?.img?.stPointId, facilityKind: detailInfo?.facilityKind });

  //종점 이미지
  const endImage = query.files.useImages({ pointId: detailInfo?.img?.endPointId, facilityKind: detailInfo?.facilityKind });

  //측점 이미지
  const etcImage = query.files.useImages({ pointId: detailInfo?.pointId, facilityKind: detailInfo?.facilityKind });
  //공통 코드 쿼리
  const code = query.codes.useCodes();
  //2.5D / 3D 지도로 이동(각각 state 전달)
  const onNavigateMap = (type: '2D' | '3D') => {
    if (type === '2D') {
      navigate('/map', {
        state: {
          type: Object.keys(detailInfo).includes('pipeId') ? 'pipe' : 'point',
          id: Object.keys(detailInfo).includes('pipeId') ? detailInfo.pipeId : detailInfo.pointId,
          facilityKind: detailInfo.facilityKind,
        },
      });
    } else if (type === '3D') {
      onOpen3D(
        `${unityScheme}?token=${sessionStorage.getItem('token')}&facilityKind=${detailInfo.facilityKind}&id=${Object.keys(detailInfo).includes('pipeId') ? detailInfo.pipeId : detailInfo.pointId}&type=${detailInfo.type}&siteId=${sessionStorage.getItem('siteId')}`, t
      );
    }
  };
  const pointCode = code.data?.PIT.filter(({ code }) => code === detailInfo.pitCde)[0]?.code;

  //이미지 팝업 open
  const onImageClick = (num: number) => {
    setThumbsSwiper(null);
    setPopupState(true);
    setImgNum(num);
  };
  //이미지 팝업 close
  const onPopupClose = () => {
    setPopupState(false);
  };

  useEffect(() => {
    if (stImage.data) {
      setStImages(stImage.data);
    }
  }, [stImage.data]);

  useEffect(() => {
    if (endImage.data) {
      setEndImages(endImage.data);
    }
  }, [endImage.data]);

  useEffect(() => {
    if (etcImage.data) {
      setStImages(etcImage.data);
    }
  }, [etcImage.data]);

  return (
    <ReportStyle.PhotoArea>
      <ReportStyle.PhotoHead>
        <ReportStyle.PhotoTitle>{t('이미지')}</ReportStyle.PhotoTitle>
      </ReportStyle.PhotoHead>
      <ReportStyle.PhotoWrap className={'cScroll'}>
        <>
          <ReportStyle.PhotoCont>
            <ReportStyle.PhotoInner>
              {/* 공통 */}
              <ReportStyle.PhotoItem>
                <ReportStyle.PhotoName>
                  <span>{t('측점번호')}</span>
                  {selectType === 'pipe' ? detailInfo?.img?.stPointPtNum : detailInfo?.ptNum}
                </ReportStyle.PhotoName>
                <Swiper
                  slidesPerView={1}
                  className={'photoSwiper'}
                  pagination={{
                    clickable: true,
                  }}
                  navigation={true}
                  modules={[Pagination, Navigation]}
                >
                  <SwiperSlide>
                    <ReportStyle.PhotoGroup>
                      {stImages.filter((i) => i.imageType === 'NEAR_IMAGE').length ? (
                        <ReportStyle.PhotoBox onClick={() => onImageClick(0)}>
                          <span>{t('근경')}</span>
                          <img src={stImages.filter((i) => i.imageType === 'NEAR_IMAGE')[0]?.imageThumbUrl || ''} alt={t('근경')} />
                        </ReportStyle.PhotoBox>
                      ) : (
                        <ReportStyle.PhotoBox className={'null'}>
                          <ReportStyle.PhotoNull></ReportStyle.PhotoNull>
                        </ReportStyle.PhotoBox>
                      )}
                      {stImages.filter((i) => i.imageType === 'DISTANT_IMAGE').length ? (
                        <ReportStyle.PhotoBox onClick={() => onImageClick(1)}>
                          <span>{t('원경')}</span>
                          <img src={stImages.filter((i) => i.imageType === 'DISTANT_IMAGE')[0]?.imageThumbUrl || ''} alt={t('원경')} />
                        </ReportStyle.PhotoBox>
                      ) : (
                        <ReportStyle.PhotoBox className={'null'}>
                          <ReportStyle.PhotoNull></ReportStyle.PhotoNull>
                        </ReportStyle.PhotoBox>
                      )}
                    </ReportStyle.PhotoGroup>
                  </SwiperSlide>
                  {stImages.filter((i) => i.imageType === 'EL_NEAR_IMAGE' || i.imageType === 'EL_DISTANT_IMAGE').length ? (
                    <SwiperSlide>
                      <ReportStyle.PhotoGroup>
                        {stImages.filter((i) => i.imageType === 'EL_NEAR_IMAGE')[0] ? (
                          <ReportStyle.PhotoBox onClick={() => onImageClick(2)}>
                            <span>{t('근경')}</span>
                            <img src={stImages.filter((i) => i.imageType === 'EL_NEAR_IMAGE')[0]?.imageThumbUrl || ''} alt={t('근경')} />
                          </ReportStyle.PhotoBox>
                        ) : (
                          <ReportStyle.PhotoBox className={'null'}>
                            <ReportStyle.PhotoNull></ReportStyle.PhotoNull>
                          </ReportStyle.PhotoBox>
                        )}
                        {stImages.filter((i) => i.imageType === 'EL_DISTANT_IMAGE').length ? (
                          <ReportStyle.PhotoBox onClick={() => onImageClick(3)}>
                            <span>{t('원경')}</span>
                            <img src={stImages.filter((i) => i.imageType === 'EL_DISTANT_IMAGE')[0]?.imageThumbUrl || ''} alt={t('원경')} />
                          </ReportStyle.PhotoBox>
                        ) : (
                          <ReportStyle.PhotoBox className={'null'}>
                            <ReportStyle.PhotoNull></ReportStyle.PhotoNull>
                          </ReportStyle.PhotoBox>
                        )}
                      </ReportStyle.PhotoGroup>
                    </SwiperSlide>
                  ) : null}
                </Swiper>
              </ReportStyle.PhotoItem>

              {/* 파이프 종점 */}
              {selectType === 'pipe' ? (
                <ReportStyle.PhotoItem>
                  <ReportStyle.PhotoName>
                    <span>{t('측점번호')}</span>
                    {detailInfo.img.endPointPtNum}
                  </ReportStyle.PhotoName>
                  <Swiper
                    slidesPerView={1}
                    className={'photoSwiper'}
                    pagination={{
                      clickable: true,
                    }}
                    navigation={true}
                    modules={[Pagination, Navigation]}
                  >
                    <SwiperSlide>
                      <ReportStyle.PhotoGroup>
                        {endImages.filter((i) => i.imageType === 'NEAR_IMAGE').length ? (
                          <ReportStyle.PhotoBox onClick={() => onImageClick(4)}>
                            <span>{t('근경')}</span>
                            <img src={endImages.filter((i) => i.imageType === 'NEAR_IMAGE')[0]?.imageThumbUrl || ''} alt={t('근경')} />
                          </ReportStyle.PhotoBox>
                        ) : (
                          <ReportStyle.PhotoBox className={'null'}>
                            <ReportStyle.PhotoNull></ReportStyle.PhotoNull>
                          </ReportStyle.PhotoBox>
                        )}
                        {endImages.filter((i) => i.imageType === 'DISTANT_IMAGE').length ? (
                          <ReportStyle.PhotoBox onClick={() => onImageClick(5)}>
                            <span>{t('원경')}</span>
                            <img src={endImages.filter((i) => i.imageType === 'DISTANT_IMAGE')[0]?.imageThumbUrl || ''} alt={t('원경')} />
                          </ReportStyle.PhotoBox>
                        ) : (
                          <ReportStyle.PhotoBox className={'null'}>
                            <ReportStyle.PhotoNull></ReportStyle.PhotoNull>
                          </ReportStyle.PhotoBox>
                        )}
                      </ReportStyle.PhotoGroup>
                    </SwiperSlide>
                    {endImages.filter((i) => i.imageType === 'EL_NEAR_IMAGE' || i.imageType === 'EL_DISTANT_IMAGE').length ? (
                      <SwiperSlide>
                        <ReportStyle.PhotoGroup>
                          {endImages.filter((i) => i.imageType === 'EL_NEAR_IMAGE')[0].imageThumbUrl ? (
                            <ReportStyle.PhotoBox onClick={() => onImageClick(6)}>
                              <span>{t('근경')}</span>
                              <img src={endImages.filter((i) => i.imageType === 'EL_NEAR_IMAGE')[0]?.imageThumbUrl || ''} alt={t('근경')} />
                            </ReportStyle.PhotoBox>
                          ) : (
                            <ReportStyle.PhotoBox className={'null'}>
                              <ReportStyle.PhotoNull></ReportStyle.PhotoNull>
                            </ReportStyle.PhotoBox>
                          )}
                          {endImages.filter((i) => i.imageType === 'EL_DISTANT_IMAGE')[0].imageThumbUrl ? (
                            <ReportStyle.PhotoBox onClick={() => onImageClick(7)}>
                              <span>{t('원경')}</span>
                              <img src={endImages.filter((i) => i.imageType === 'EL_DISTANT_IMAGE')[0]?.imageThumbUrl || ''} alt={t('원경')} />
                            </ReportStyle.PhotoBox>
                          ) : (
                            <ReportStyle.PhotoBox className={'null'}>
                              <ReportStyle.PhotoNull></ReportStyle.PhotoNull>
                            </ReportStyle.PhotoBox>
                          )}
                        </ReportStyle.PhotoGroup>
                      </SwiperSlide>
                    ) : null}
                  </Swiper>
                </ReportStyle.PhotoItem>
              ) : null}
            </ReportStyle.PhotoInner>
          </ReportStyle.PhotoCont>
          {code.isSuccessAll && detailInfo ? (
            <ReportStyle.DetailInfoArea>
              <ReportStyle.DetailInfoHead>
                <ReportStyle.DetailInfoTitle>{t('상세정보')}</ReportStyle.DetailInfoTitle>
              </ReportStyle.DetailInfoHead>
              <ReportStyle.DetailInfoCont>
                <ReportStyle.DetailInfoBox>
                  <>
                    {/*<ReportStyle.DetailInfoItem>*/}
                    {/*  {t('업체명')}*/}
                    {/*  <span>{detailInfo.manufacturer || '-'}</span>*/}
                    {/*</ReportStyle.DetailInfoItem>*/}

                    {selectType === 'pipe' ? (
                      <ReportStyle.DetailInfoItem>
                        {t('품명')}
                        <span>{t(code.data?.MOP.filter(({ code }) => code === detailInfo.mopCde)[0]?.value) || '-'}</span>
                      </ReportStyle.DetailInfoItem>
                    ) : null}

                    <ReportStyle.DetailInfoItem>
                      {t('관종')}
                      <span>{t(code.data?.FACILITY.filter(({ code }) => code === detailInfo.facilityKind)[0]?.value || '-')}</span>
                    </ReportStyle.DetailInfoItem>

                    {selectType === 'point' ? (
                      pointCode === 'PIT007' ? (
                        <ReportStyle.DetailInfoItem>
                          {t('종류')}
                          <span>{t(code.data?.SBA.filter(({ code }) => code === detailInfo.sbaCde)[0]?.value) || '-'}</span>
                        </ReportStyle.DetailInfoItem>
                      ) : pointCode === 'PIT006' ? (
                        <ReportStyle.DetailInfoItem>
                          {t('종류')}
                          <span>{t(code.data?.PVE.filter(({ code }) => code === detailInfo.pveCde)[0]?.value) || '-'}</span>
                        </ReportStyle.DetailInfoItem>
                      ) : pointCode === 'PIT008' ? (
                        <ReportStyle.DetailInfoItem>
                          {t('종류')}
                          <span>{t(code.data?.PFR.filter(({ code }) => code === detailInfo.pfrCde)[0]?.value) || '-'}</span>
                        </ReportStyle.DetailInfoItem>
                      ) : (
                        <ReportStyle.DetailInfoItem>
                          {t('종류')}
                          <span>{t(code.data?.PIT.filter(({ code }) => code === detailInfo.pitCde)[0]?.value) || '-'}</span>
                        </ReportStyle.DetailInfoItem>
                      )
                    ) : null}

                    {selectType === 'pipe' ? (
                      <ReportStyle.DetailInfoItem>
                        {t('관유형')}
                        <span>{t(code.data?.PIP.filter(({ code }) => code === detailInfo.pipCde)[0]?.value) || '-'}</span>
                      </ReportStyle.DetailInfoItem>
                    ) : pointCode === 'PIT007' ? (
                      <ReportStyle.DetailInfoItem>
                        {t('형태')}
                        <span>{t(code.data?.FOR.filter(({ code }) => code === detailInfo.forCde)[0]?.value) || '-'}</span>
                      </ReportStyle.DetailInfoItem>
                    ) : null}

                    {pointCode === 'PIT006' || pointCode === 'PIT007' || pointCode === 'PIT008' ? (
                      <ReportStyle.DetailInfoItem>
                        {t('재질')}
                        <span>{t(code.data?.MOP.filter(({ code }) => code === detailInfo.mopCde)[0]?.value) || '-'}</span>
                      </ReportStyle.DetailInfoItem>
                    ) : null}

                    {pointCode === 'PIT007' ? (
                      <ReportStyle.DetailInfoItem>
                        {t('규격')}
                        <span>{code.data?.FOR.filter(({ code }) => code === detailInfo.forCde)[0]?.code === 'FOR024' ? detailInfo.hol + 'm*' + detailInfo.vel + 'm' : detailInfo.diameter + 'mm'}</span>
                      </ReportStyle.DetailInfoItem>
                    ) : pointCode === 'PIT006' || pointCode === 'PIT008' ? (
                      <ReportStyle.DetailInfoItem>
                        {t('관경')}
                        <span>{detailInfo.diameter ? detailInfo.diameter + 'mm' : '-'}</span>
                      </ReportStyle.DetailInfoItem>
                    ) : null}

                    {pointCode === 'PIT007' ? (
                      <ReportStyle.DetailInfoItem>
                        {t('높이')}
                        <span>{detailInfo.high || '-' + ' m'}</span>
                      </ReportStyle.DetailInfoItem>
                    ) : null}
                  </>
                </ReportStyle.DetailInfoBox>
              </ReportStyle.DetailInfoCont>
              <ReportStyle.BtnGroup>
                <ReportStyle.MapLinkBtn type={'button'} onClick={() => onNavigateMap('2D')}>
                  {t('2.5D지도로보기')}
                </ReportStyle.MapLinkBtn>
                <ReportStyle.MapLinkBtn type={'button'} onClick={() => onNavigateMap('3D')}>
                  {t('3D지도로보기')}
                </ReportStyle.MapLinkBtn>
              </ReportStyle.BtnGroup>
            </ReportStyle.DetailInfoArea>
          ) : null}
        </>

        {/*<ReportStyle.Inner style={{ height: 'auto', paddingTop: 80, textAlign: 'center' }}>*/}
        {/*  <img src={IcoNoData} alt={'선택된 데이터가 없습니다.'} />*/}
        {/*  <ReportStyle.NoData>선택한 데이터가 없습니다.</ReportStyle.NoData>*/}
        {/*</ReportStyle.Inner>*/}
      </ReportStyle.PhotoWrap>

      {/*<LargeImagePopup pointImgList={pointImgList} />*/}

      {popupState ? <PopupReportImage onPopupClose={onPopupClose} thumbsSwiper={thumbsSwiper} setThumbsSwiper={setThumbsSwiper} stImages={stImages} endImages={endImages} initialSlide={imgNum} detailInfo={detailInfo} /> : null}
    </ReportStyle.PhotoArea>
  );
};

export default ReportPhotoInfo;
