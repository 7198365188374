import React, { useState, useEffect } from 'react';
import PopupFrame from './PopupFrame';
import Input from 'components/atoms/Input';
import { useSetRecoilState } from 'recoil';
import { popupAddSite } from 'store/popupAtoms';
import LabelInput, { LabelForm } from 'components/atoms/LabelInput';
import { DateObject } from 'react-multi-date-picker';
import DashboardCalendar from 'components/templates/DashboardCalendar';
import Select from 'components/atoms/Select';
import Checkbox from 'components/atoms/Checkbox';
import useInputChange from 'hooks/useInputChange';
import useMutateHook from 'hooks/RQuery/useMutateHook';
import { postSurveyAddSite } from 'service/api/common';
import query from 'hooks/RQuery/utils';
import { useTranslation } from 'react-i18next';

// { text: '전체', value: 'ALL', active: true },
// { text: '사업장', value: 'SITE_NAME', active: false },
// { text: '발주처', value: 'OWNER', active: false },
// { text: '주소', value: 'ADDRESS', active: false },

//기간 선택 변경
// const onChange = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
//    // onParamChange(e)
//    const value = (e.target as any).ariaValueText;
//    const newArr: OptionProps[] = [];
//    option.map((i) => {
//      newArr.push({ ...i, active: value === i.value });
//    });
//    setOption(newArr);
//  };

const initForm = {
  siteName: '',
  projection: 'EPSG:5187',
  projectName: '',
  address: '부산 스마트빌리지',
  observer: '함동관',
  constructionStartDate: '2024-02-11',
  constructionEndDate: '2025-04-30',
};
const initFormConstInfo = {
  companyName: '현대중공업',
  managerName: '홍길동',
  email: 'hgd.mv@movements.kr',
  officeNumber: '02-134-1234',
  phoneNumber: '010-1234-1234',
};

function PopupAddSite(props) {
  const {t} = useTranslation()
  const setPopupAddSite = useSetRecoilState(popupAddSite);

  const code = query.codes.useCodes(); // 코드 조회
  const facilityList = code?.data?.FACILITY;
  //   console.log(facilityList);

  const [facilityOption, setFacilityOption] = useState([{ text: '', value: '', amount: 0, checked: false }]);
  //   console.log(facilityOption);

  // 기본 정보
  const [formOrigin, setFormOrigin, onChangeOrigin] = useInputChange(initForm);
  // constructionInfo : owner
  const [formOwner, setFormOwner, onChangeOwner] = useInputChange(initFormConstInfo);
  // constructionInfo : contractor
  const [formCont, setFormCont, onChangeCont] = useInputChange(initFormConstInfo);
  // constructionInfo : subContractor
  const [formSubCont, setFormSubCont, onChangeSubCont] = useInputChange(initFormConstInfo);

  //   const [date, setDate] = useState<DateObject>(new DateObject());

  const { mutate: mutateAddSite } = useMutateHook({ fetchApi: postSurveyAddSite, invalidQuery: [[]] });

  useEffect(() => {
    if (facilityList[0]) {
      let newList = facilityList?.map((ele) => ({
        text: ele?.value,
        value: ele?.code,
        checked: false,
        amount: 0,
      }));
      setFacilityOption(newList);
    }
  }, [facilityList]);

  // 현장 등록 팝업 (현재팝업) 닫기
  const closePopupAddSite = () => {
    setPopupAddSite((prev) => ({
      ...prev,
      isOpen: false,
    }));
  };

  // 등록하기 mutate
  const serverAddSite = async () => {
    let checkedFacility = facilityOption
      ?.filter((ele) => ele.checked)
      ?.map((ele) => {
        return { facilityKind: ele.value, planAmount: ele.amount };
      });
    console.log(checkedFacility);

    await mutateAddSite({
      ...formOrigin,
      facilityKindList: [...checkedFacility],
      constructionInfo: {
        owner: {
          ...formOwner,
        },
        contractor: {
          ...formCont,
        },
        subContractor: {
          ...formSubCont,
        },
      },
    });
  };

  // facility 체크박스 이벤트
  const onChangeCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setFacilityOption((prev) => {
      return [...prev].map((ele) => (ele.value === name ? { ...ele, checked: checked } : ele));
    });
  };
  return (
    <PopupFrame title="현장 등록(개발자용)" close={closePopupAddSite} leftFunc={closePopupAddSite} leftText="취소" rightText="등록" rightFunc={serverAddSite}>
      <div className="inputWrap cScroll" style={{ maxHeight: '600px', display: 'flex', gap: '10px', flexDirection: 'column' }}>
        <LabelInput name="siteName" label="siteName" placeholder="부산2-5공구 상수도 공사" onChange={onChangeOrigin} value={formOrigin?.siteName} />
        <LabelInput name="projection" label="projection" placeholder={'EPSG:5187'} onChange={onChangeOrigin} value={formOrigin?.projection} />
        <LabelInput name="projectName" label="projectName" placeholder={'무브먼츠 부산 EDC2-5공구'} onChange={onChangeOrigin} value={formOrigin?.projectName} />
        <LabelInput name="address" label="address" placeholder={'부산 스마트빌리지'} onChange={onChangeOrigin} value={formOrigin?.address} />
        <LabelInput name="observer" label="observer" placeholder={'함동관'} onChange={onChangeOrigin} value={formOrigin?.observer} />
        <LabelInput name="constructionStartDate" label="constructionStartDate" placeholder={'2024-02-11'} onChange={onChangeOrigin} value={formOrigin?.constructionStartDate} />
        <LabelInput name="constructionEndDate" label="constructionEndDate" placeholder={'2025-04-30'} onChange={onChangeOrigin} value={formOrigin?.constructionEndDate} />
        <LabelForm.Wrap>
          <LabelForm.Label>facilityKindList</LabelForm.Label>
          <div className="checkList" style={{ width: '300px', display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            {facilityOption?.map((ele, idx) => (
              <>
                <Checkbox
                  key={ele.value + idx}
                  // type="radio"
                  id={ele?.value}
                  name={ele?.value}
                  text={ele?.text}
                  dark={true}
                  size={18}
                  checked={ele?.checked}
                  onChange={onChangeCheck}
                />
                <LabelInput
                  name={ele?.value}
                  width={194}
                  label="planAmount"
                  placeholder={t('설계량입력')}
                  onChange={(e) => {
                    setFacilityOption((prev) => {
                      return [...prev].map((ele) => (ele.checked && ele.value === e.target.name ? { ...ele, amount: Number(e.target.value) } : ele));
                    });
                  }}
                  value={ele.amount || 0}
                />
              </>
            ))}
          </div>
        </LabelForm.Wrap>

        <div>------------constructionInfo-------------------------------------------------------------------</div>
        <div>------------owner-------------------------------------------------------------------</div>
        <LabelInput name="companyName" label="companyName" placeholder="현대중공업" onChange={onChangeOwner} value={formOwner.companyName} />
        <LabelInput name="managerName" label="managerName" placeholder="홍길동" onChange={onChangeOwner} value={formOwner.managerName} />
        <LabelInput name="email" label="email" placeholder="hgd.mv@movements.kr" onChange={onChangeOwner} value={formOwner.email} />
        <LabelInput name="officeNumber" label="officeNumber" placeholder="02-134-1234" onChange={onChangeOwner} value={formOwner.officeNumber} />
        <LabelInput name="phoneNumber" label="phoneNumber" placeholder="010-1234-1234" onChange={onChangeOwner} value={formOwner.phoneNumber} />
        <div>------------contractor-------------------------------------------------------------------</div>
        <LabelInput name="companyName" label="companyName" placeholder="현대중공업" onChange={onChangeCont} value={formCont.companyName} />
        <LabelInput name="managerName" label="managerName" placeholder="홍길동" onChange={onChangeCont} value={formCont.managerName} />
        <LabelInput name="email" label="email" placeholder="hgd.mv@movements.kr" onChange={onChangeCont} value={formCont.email} />
        <LabelInput name="officeNumber" label="officeNumber" placeholder="02-134-1234" onChange={onChangeCont} value={formCont.officeNumber} />
        <LabelInput name="phoneNumber" label="phoneNumber" placeholder="010-1234-1234" onChange={onChangeCont} value={formCont.phoneNumber} />
        <div>------------subContractor-------------------------------------------------------------------</div>
        <LabelInput name="companyName" label="companyName" placeholder="현대중공업" onChange={onChangeSubCont} value={formSubCont.companyName} />
        <LabelInput name="managerName" label="managerName" placeholder="홍길동" onChange={onChangeSubCont} value={formSubCont.managerName} />
        <LabelInput name="email" label="email" placeholder="hgd.mv@movements.kr" onChange={onChangeSubCont} value={formSubCont.email} />
        <LabelInput name="officeNumber" label="officeNumber" placeholder="02-134-1234" onChange={onChangeSubCont} value={formSubCont.officeNumber} />
        <LabelInput name="phoneNumber" label="phoneNumber" placeholder="010-1234-1234" onChange={onChangeSubCont} value={formSubCont.phoneNumber} />
      </div>
    </PopupFrame>
  );
}

export default React.memo(PopupAddSite);
