import OlCore from '../OlCore';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import VectorModule from '../Module/VectorModule';
import LineStringModule from '../Module/LineStringModule';
import { Circle, Fill, Style, Text } from 'ol/style';
import { Stroke } from 'ol/style.js';
import { css } from 'styled-components';
import getFacilityColor from '../style/getFacilityColor';

class ObjectDesign extends LineStringModule {
  private layerList: VectorLayer<VectorSource>[] = [];

  public facilityList = [
    { value: '상수', code: 'WATER', show: true },
    { value: '오수', code: 'WASTE', show: true },
    { value: '우수', code: 'RAIN', show: true },
    { value: '가스', code: 'GAS', show: true },
    { value: '통신', code: 'NETWORK', show: true },
    { value: '전기', code: 'ELECTRIC', show: true },
    { value: '송유', code: 'OIL', show: true },
    { value: '난방', code: 'HEAT', show: true },
  ];

  public facilityPointList = [
    { value: '상수', code: 'WATER', show: true },
    { value: '오수', code: 'WASTE', show: true },
    { value: '우수', code: 'RAIN', show: true },
    { value: '가스', code: 'GAS', show: true },
    { value: '통신', code: 'NETWORK', show: true },
    { value: '전기', code: 'ELECTRIC', show: true },
    { value: '송유', code: 'OIL', show: true },
    { value: '난방', code: 'HEAT', show: true },
  ];

  //레이어 초기화
  constructor(core: OlCore) {
    super(core);
    this.facilityList.map((i) => {
      const layer = new VectorLayer<VectorSource>({
        properties: {
          id: i.code + 'Line',
        },
        zIndex: 4,
        visible: false,
      });
      this.layerList.push(layer);
    });
    this.facilityPointList.map((i) => {
      const layer = new VectorLayer<VectorSource>({
        properties: {
          id: i.code + 'Text',
        },
        zIndex: 4,
        visible: false,
      });
      this.layerList.push(layer);
    });
    this.setLayers([...this.layerList]);
  }

  //설계도면 그리기
  public draw(datas) {
    // 초기화
    this.layerList.map((layer) => {
      layer.getSource()?.clear();
    });
    const values: any = Object.values(datas);
    const newArr: any = []; // 라인 가공
    const newArr2: any = []; // 라벨 가공
    values?.map((facility) => {
      facility?.lines?.map((i) => {
        const newCoord = i.coordinate?.map((j) => {
          return [j.x, j.y];
        });

        if (newArr.filter(({ layerId }) => i.facilityKind + 'Line' === layerId).length) {
          newArr.filter(({ layerId }) => i.facilityKind + 'Line' === layerId)[0].coordinate.push(newCoord);
        } else {
          newArr.push({ layerId: i.facilityKind + 'Line', coordinate: [newCoord] });
        }
      });
      facility?.texts?.map((i) => {
        const newCoord = [i.coordinate.x, i.coordinate.y];
        if (newArr2.filter(({ layerId }) => i.facilityKind + 'Text' === layerId).length) {
          newArr2
            .filter(({ layerId }) => i.facilityKind + 'Text' === layerId)[0]
            .data.push({ geometry: { coordinates: newCoord }, properties: { layerId: i.facilityKind + 'Text', valign: i.valign, halign: i.halign, rotation: i.rotation, text: i.text, size: i.size } });
        } else {
          newArr2.push({ layerId: i.facilityKind + 'Text', data: [{ geometry: { coordinates: newCoord }, properties: { layerId: i.facilityKind + 'Text', valign: i.valign, halign: i.halign, rotation: i.rotation, text: i.text, size: i.size } }] });
        }
      });
    });
    newArr.map((i, index) => {
      const lines = this.createLineStringFeatures(i.coordinate);
      const source = this.createSource(lines);
      this.layerList
        .filter((layer) => layer.get('id') === i.layerId)
        .map((layer) => {
          layer.setSource(source);
        });
    });
    newArr2.map((i, index) => {
      const texts = this.createFeatures(i.data);
      const source = this.createSource(texts);
      this.layerList
        .filter((layer) => layer.get('id') === i.layerId)
        .map((layer) => {
          layer.setSource(source);
        });
    });
    //layerList별 스타일 추가
    this.layerList.map((i) => {
      i.setStyle((feature) => {
        const isText = (feature.getGeometry() as any).getType() === 'Point';
        const textProperties = feature.getProperties().properties;
        const resol = this.core.mapInstance.getView().getResolution()!;
        const zoom = this.core.mapInstance.getView().getZoom()!;

        if (isText) {
          const rotation = (360 - textProperties.rotation) * (Math.PI / 180);
          return [
            new Style({
              text: new Text({
                text: textProperties.text,
                font: `300 ${textProperties.size}px Pretendard `,
                scale: resol ? Math.min((0.3 / resol) * (1 / zoom), 50 / textProperties.size) : 1,
                fill: new Fill({ color: getFacilityColor(i.get('id'), 1) }),
                stroke: new Stroke({ width: 1, color: '#fff' }),
                rotation: rotation,
                textAlign: textProperties.halign.toLowerCase(),
                textBaseline: textProperties.valign.toLowerCase(),
              }),
            }),
          ];
        } else {
          return [
            new Style({
              stroke: new Stroke({
                width: 1,
                // color: facilityColor(),
                color: getFacilityColor(i.get('id'), 1),
              }),
            }),
          ];
        }
      });
    });
  }

  //설계도면 토글
  public toggleDesign(key) {
    this.layerList.map((i) => {
      if (i.get('id') === key + 'Line' || i.get('id') === key + 'Text') {
        i.setVisible(!i.getVisible());
      }
    });
  }

  //설계도면 on
  public showDesign(key) {
    this.layerList.map((i) => {
      if (i.get('id') === key + 'Line' || i.get('id') === key + 'Text') {
        i.setVisible(true);
        if (i.getSource()) {
          if (!i.getSource()?.getExtent().includes(Infinity)) {
            setTimeout(() => {
              this.core.mapInstance.getView().fit(i.getSource()!.getExtent()!, { duration: 500 });
            }, 100);
          }
        }
      } else {
        i.setVisible(false);
      }
    });
  }
}

export default ObjectDesign;
