import React, { useEffect, useState } from 'react';
import { Cell, RadialBar, RadialBarChart, ResponsiveContainer } from 'recharts';
import { DashboardStyle } from './_style';
import BarChart from '../atoms/BarChart';
import { DashboardFacilityDataProps, DashboardTypeDataProps } from '../../pages/_interface';
import { useRecoilState } from 'recoil';
import { themeState } from '../../store/Atoms';
import { DateObject } from 'react-multi-date-picker';
import SkeletonFacilityChart from '../skeleton/SkeletonFacilityChart';
import { useTranslation } from 'react-i18next';

const DashboardFacilityChart = ({ data }: { data: DashboardTypeDataProps[] }) => {
  const { t } = useTranslation();
  const [themeMode] = useRecoilState(themeState);
  return (
    <DashboardStyle.SectionBox style={{ marginLeft: 15 }}>
      <SkeletonFacilityChart />
      <DashboardStyle.TitleArea>
        <DashboardStyle.Title>{t('시설별시공량')}(m)</DashboardStyle.Title>
      </DashboardStyle.TitleArea>
      <DashboardStyle.DescArea>
        <DashboardStyle.DescInner style={{ display: 'flex', alignItems: 'center' }}>
          <DashboardStyle.ChartFlex style={{ alignItems: data.length > 5 ? 'flex-start' : 'center', flex: 1, height: 'auto' }}>
            {data.length <= 5 ? (
              data.length === 2 ? null : (
                <DashboardStyle.ChartBox style={{ width: 168, height: 168, marginRight: 32 }}>
                  <ResponsiveContainer width={'100%'} height={'100%'}>
                    <RadialBarChart cx="50%" cy="50%" innerRadius="130%" outerRadius="60%" barSize={8} data={data} startAngle={270} endAngle={-90}>
                      <RadialBar cornerRadius={50} dataKey={'percent'} background={{ fill: themeMode === 'dark' ? '#323D4E' : '#E2E4ED' }} />

                      {/*<Legend iconSize={10} layout="vertical" verticalAlign="middle" wrapperStyle={style} />*/}
                    </RadialBarChart>
                  </ResponsiveContainer>
                </DashboardStyle.ChartBox>
              )
            ) : null}
            <div style={{ flex: 1 }}>
              {data
                .filter((i, index) => i.name !== 'total')
                .map((i, index) => {
                  if (index <= 3) {
                    return <BarChart value={Number(i.meter).toLocaleString('ko-KR') + ' / ' + Number(i.planAmount).toLocaleString('ko-KR') + 'm'} percent={i.percent} label={t(i.name)} color={i.fill} key={i.name} />;
                  }
                })}
            </div>
            {data.length > 5 ? (
              <div style={{ flex: 1, marginLeft: 20 }}>
                {data
                  .filter((i, index) => i.name !== 'total')
                  .map((i, index) => {
                    if (index > 3) {
                      return <BarChart value={Number(i.meter).toLocaleString('ko-KR') + ' / ' + Number(i.planAmount).toLocaleString('ko-KR') + 'm'} percent={i.percent} label={t(i.name)} color={i.fill} key={i.name} />;
                    }
                  })}
              </div>
            ) : null}
          </DashboardStyle.ChartFlex>
        </DashboardStyle.DescInner>
      </DashboardStyle.DescArea>
    </DashboardStyle.SectionBox>
  );
};

export default DashboardFacilityChart;
