/**
 * Return list of months
 * 🌍 localeName   : name of local
 *  ✅ monthFormat : short, numeric, long (Default)
 */
import ExcelJS from 'exceljs';
import JSZip from 'jszip';
import { getImageBuffer } from '../service/api/common';
import pipeFunc from './excelReport/pipeSheet';
import holeFunc from './excelReport/holeSheet';
import valveFunc from './excelReport/valveSheet';
import statisticsFunc from './excelReport/statisticsFunc';
import IcoMenu01 from '../assets/images/ico-menu-01.svg';
import IcoMenu01Active from '../assets/images/ico-menu-01-active.svg';
import IcoMenu02 from '../assets/images/ico-menu-02.svg';
import IcoMenu02Active from '../assets/images/ico-menu-02-active.svg';
import IcoMenu03 from '../assets/images/ico-menu-03.svg';
import IcoMenu03Active from '../assets/images/ico-menu-03-active.svg';
import IcoMenu04 from '../assets/images/ico-menu-04.svg';
import IcoMenu04Active from '../assets/images/ico-menu-04-active.svg';
import NotFound from '../pages/NotFound';
import CompanyList from '../pages/superAdmin/Company';
import CompanyDetail from '../pages/superAdmin/CompanyDetail';
import CompanyRegister from '../pages/superAdmin/CompanyRegister';
import Map from '../pages/Map';
import Dashboard from '../pages/Dashboard';
import Report from '../pages/Report';
import ExportPdf from '../components/templates/pdf/ExportPdf';
import Survey from '../pages/survey/Survey';
import SurveyDetail from '../pages/survey/SurveyDetail';
import WorkPlace from '../pages/admin/WorkPlace';
import WorkPlaceDetail from '../pages/admin/WorkPlaceDetail';
import WorkPlaceRegister from '../pages/admin/WorkPlaceRegister';
import User from '../pages/admin/User';
import UserRegister from '../pages/admin/UserRegister';
import { deployKey } from '../components/templates/2dMap/PipeInfoModal';
const loginId = sessionStorage.getItem('loginId');

export const unityScheme = `${deployKey === 'origin' ? (loginId === 'kcity' || loginId === 'kcity_admin' ? 'd2viet' : 'd2man') : 'd2dev'}://`;

export function MonthsForLocale(locale: 'en' | 'ko' = 'ko', monthFormat: 'long' | 'short' | 'numeric' = 'short') {
  const { format } = new Intl.DateTimeFormat(locale, { month: monthFormat });

  // return [...Array(12).keys()].map((m) => format(new Date(Date.UTC(2021, m % 12))));
  return [...Array(12).keys()].map((m) => format(new Date(2021, m)));
}

/**
 * Return list of days
 * 🌍 locale : name of local, default ko
 * ✅ weekday : format of weekday short/long/narrow
 */
export function DaysForLocale(locale: 'en' | 'ko' = 'ko', weekday: 'long' | 'short' | 'narrow' = 'short'): string[] {
  const { format } = new Intl.DateTimeFormat(locale, { weekday });
  const weekStartNum = 1; // 1: 일~ , 5: 월~
  return [...Array(7).keys()].map((day) => format(new Date(Date.UTC(2021, weekStartNum, day))));
}

// yyyy-mm-dd 변환 (연월일)
export function convertDateFormat(dateString?: any, t?: any) {
  var parts = String(dateString)?.split('-') || [];
  var year = parts?.[0] || '-';
  var month = parts?.[1] || '-';
  var day = parts?.[2] || '-';

  return year + `${t('년')} ` + month + `${t('월')} ` + day + `${t('일')}`;
}

export function isNumber(value) {
  return typeof value === 'number';
}

//3D 실행버튼 클릭
export const onOpen3D = (urlScheme: string, t?:any) => {
  let windowState = 'focus';
  const focus = () => {
    windowState = 'focus';
  };
  const blur = () => {
    windowState = 'blur';
  };

  window.addEventListener('focus', focus);
  window.addEventListener('blur', blur);

  window.location.href = urlScheme;

  setTimeout(() => {
    //파일 설치여부 확인
    if (windowState === 'focus') {
      if (navigator.userAgent.indexOf('Mac') !== -1) {
        //접속기기가 맥북
        if (window.confirm(t('유니티 프로그램이 설치되지 않았습니다. 설치하시겠습니까?'))) {
          // alert('Mac 설치파일 다운로드 로직');
          const newBtn = document.createElement('a') as any;
          newBtn.target = '_blank';
          newBtn.href = '/d2/download/application/DTX3D_mac.zip';
          newBtn.click();
        }
      } else {
        if (window.confirm(t('유니티 프로그램이 설치되지 않았습니다. 설치하시겠습니까?'))){
          const newBtn = document.createElement('a') as any;
          newBtn.target = '_blank';
          newBtn.href = '/d2/download/application/DTX3D_win.zip';
          newBtn.click();
        }
      }
    }
    //이벤트 제거
    window.removeEventListener('focus', focus);
    window.removeEventListener('blur', blur);
  }, 5000);
};

// 측점 이미지 데이터 종류 (고정)
export const pointImgList = [
  {
    num: 1,
    value: '근경',
    fullValue: '관로 근경',
    imageType: 'NEAR_IMAGE',
  },
  {
    num: 2,
    value: '원경',
    fullValue: '관로 원경',
    imageType: 'DISTANT_IMAGE',
  },
  {
    num: 3,
    value: '근경(지반고)',
    fullValue: '지반고 근경',
    imageType: 'EL_NEAR_IMAGE',
  },
  {
    num: 4,
    value: '원경(지반고)',
    fullValue: '지반고 원경',
    imageType: 'EL_DISTANT_IMAGE',
  },
];

export const manholeImgList = [
  {
    num: 1,
    value: '내경',
    fullValue: '내경',
    imageType: 'NEAR_IMAGE',
  },
  {
    num: 2,
    value: '전경',
    fullValue: '전경',
    imageType: 'DISTANT_IMAGE',
  },
];

export const vmfImgList = [
  {
    num: 1,
    value: '근경',
    fullValue: '근경',
    imageType: 'NEAR_IMAGE',
  },
  {
    num: 2,
    value: '원경',
    fullValue: '원경',
    imageType: 'DISTANT_IMAGE',
  },
];

export const pipePointImgList = [
  {
    num: 1,
    value: '시점 근경',
    code_thumb: 'stNearImageThumbUrl',
    code_org: 'stNearImageUrl',
    imageType: 'NEAR_IMAGE',
  },
  {
    num: 2,
    value: '시점 원경',
    code_thumb: 'stDistantImageThumbUrl',
    code_org: 'stDistantImageUrl',
    imageType: 'DISTANT_IMAGE',
  },
  {
    num: 3,
    value: '시점 근경(지반고)',
    code_thumb: 'stElNearImageThumbUrl',
    code_org: 'stElNearImageUrl',
    imageType: 'EL_NEAR_IMAGE',
  },
  {
    num: 4,
    value: '시점 원경(지반고)',
    code_thumb: 'stElDistantImageThumbUrl',
    code_org: 'stElDistantImageUrl',
    imageType: 'EL_DISTANT_IMAGE',
  },
  {
    num: 5,
    value: '종점 근경',
    code_thumb: 'endNearImageThumbUrl',
    code_org: 'endNearImageUrl',
    imageType: 'NEAR_IMAGE',
  },
  {
    num: 6,
    value: '종점 원경',
    code_thumb: 'endDistantImageThumbUrl',
    code_org: 'endDistantImageUrl',
    imageType: 'DISTANT_IMAGE',
  },
  {
    num: 7,
    value: '종점 근경(지반고)',
    code_thumb: 'endElNearImageThumbUrl',
    code_org: 'endElNearImageUrl',
    imageType: 'EL_NEAR_IMAGE',
  },
  {
    num: 8,
    value: '종점 원경(지반고)',
    code_thumb: 'endElDistantImageThumbUrl',
    code_org: 'endElDistantImageUrl',
    imageType: 'EL_DISTANT_IMAGE',
  },
];

export const FACILITY_TYPES = [
  {
    code: 'PIPE',
    value: '파이프',
  },
  {
    code: 'HOLE',
    value: '맨홀',
  },
  {
    code: 'VALVE',
    value: '밸브',
  },
];

//[olCore] LineLayer -> pipeTypeList 과 같은 용도
// 관 종류 리스트 (서버 데이터 실패시 예비 용도)
export const allFacilityList: any[] = [
  {
    code: 'WATER',
    value: '상수',
    color: '#0076FF',
    isChekced: true,
  },
  {
    code: 'WASTE',
    value: '오수',
    color: '#9324D8',
    isChekced: true,
  },
  {
    code: 'RAIN',
    value: '우수',
    color: '#F836E4',
    isChekced: true,
  },
  {
    code: 'GAS',
    color: '#E6EA46',
    value: '가스',
    isChekced: true,
  },
  {
    code: 'NETWORK',
    color: '#26CB23',
    value: '통신',
    isChekced: true,
  },
  {
    code: 'ELECTRIC',
    color: '#F63333',
    value: '전기',
    isChekced: true,
  },
  {
    code: 'OIL',
    color: '#8C5332',
    value: '송유',
    isChekced: true,
  },
  {
    code: 'HEAT',
    color: '#DD6D06',
    value: '난방',
    isChekced: true,
  },
];

// url 주소 쿼리스트링 데이터 세션에 저장
export function getUrlQueryStringData(): { id?: string; facilityType?: any; facilityKind?: any; token?: string } {
  // Get the current URL
  let currentUrl = window.location.href;

  // Parse the query string
  let queryString = currentUrl.split('?')[1]; // Get the query string part after '?'
  let params = new URLSearchParams(queryString); // Create URLSearchParams object

  let qsData = {};

  //2. You can also store individual parameters if needed
  params.forEach(function (value, key) {
    sessionStorage.setItem(key, value);
    qsData[key] = value;
  });

  return qsData;
}

export const escKeyModalClose = (e, callback) => {
  if (e.key === 'Escape') {
    callback();
  }
};

//엑셀 다운로드
export const onExportExcel = async (codes: any, list: any, workDate: string[], selectDate: { st: string; end: string }, setLoader: any) => {
  try {
    const fileList: any = [];
    const zip: any = new JSZip();
    if (selectDate) {
      workDate.map((i) => {
        if (new Date(selectDate.st).getTime() <= new Date(i).getTime() && new Date(selectDate.end).getTime() >= new Date(i).getTime()) {
        }
      });
    }
    //
    //TODO - 이미지 다운 시작 부분. 로딩바 or 텍스트 안내 추가?
    const newArr: any = [];
    const newCount: any = [];
    //
    const promises = [...workDate].map(async (i) => {
      if (new Date(selectDate.st).getTime() <= new Date(i).getTime() && new Date(selectDate.end).getTime() >= new Date(i).getTime()) {
        const newData = { ...list };
        newData.pipeInfo = newData.pipeInfo.filter(({ constructionDate }) => constructionDate === i);
        newData.manholeInfo = newData.manholeInfo.filter(({ constructionDate }) => constructionDate === i);
        newData.valveInfo = newData.valveInfo.filter(({ constructionDate }) => constructionDate === i);
        // workbook 생성
        const wb = new ExcelJS.Workbook();
        // sheet 생성
        const pipeSheet = wb.addWorksheet('PIPE');
        const valveSheet = wb.addWorksheet('VALVE');
        const holeSheet = wb.addWorksheet('MANHOLE');

        await pipeFunc(codes, newData, wb, pipeSheet);
        await holeFunc(codes, newData, wb, holeSheet);
        await valveFunc(codes, newData, wb, valveSheet);

        const b = await wb.xlsx.writeBuffer();
        const a = { date: i, blob: new Blob([b], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }) };
        fileList.push(a);
      }
    });

    const statisticFile = new Promise(async () => {
      const newData = { ...list };
      const wb = new ExcelJS.Workbook();

      newData.pipeInfo.map((i) => {
        if (newArr.filter(({ date }) => date === i.constructionDate).length) {
          newArr.filter(({ date }) => date === i.constructionDate)[0].pipe.push(i);
        } else {
          newArr.push({ date: i.constructionDate, pipe: [i], manhole: [], valve: [] });
        }
      });

      newData.manholeInfo.map((i) => {
        if (newArr.filter(({ date }) => date === i.constructionDate).length) {
          newArr.filter(({ date }) => date === i.constructionDate)[0].manhole.push(i);
        } else {
          newArr.push({ date: i.constructionDate, manhole: [i], pipe: [], valve: [] });
        }
      });

      newData.valveInfo.map((i) => {
        if (newArr.filter(({ date }) => date === i.constructionDate).length) {
          newArr.filter(({ date }) => date === i.constructionDate)[0].valve.push(i);
        } else {
          newArr.push({ date: i.constructionDate, valve: [i], pipe: [], manhole: [] });
        }
      });

      newData.facilityQuantities.map((i) => {
        newCount.push(i);
      });

      const statisticsData = newArr.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
      statisticsFunc(newCount, statisticsData, wb);
      const b = await wb.xlsx.writeBuffer();
      const a = { date: '작업 통계', blob: new Blob([b], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }) };
      fileList.push(a);
    });

    Promise.all(promises)
      .then(() => {
        fileList.forEach((i) => {
          zip.folder(`작업일보-${selectDate.st.replaceAll('-', '').substring(2)}-${selectDate.end.replaceAll('-', '').substring(2)}`).file(`${i.date}.xlsx`, i.blob);
        });

        zip.generateAsync({ type: 'blob' }).then((resZip) => {
          const url = window.URL.createObjectURL(resZip);
          const elem = document.createElement('a');
          elem.download = `작업일보-${selectDate.st.replaceAll('-', '').substring(2)}-${selectDate.end.replaceAll('-', '').substring(2)}.zip`;
          elem.href = url;
          document.body.appendChild(elem); // a 요소를 body에 추가하여 클릭 이벤트를 트리거
          elem.click();
          document.body.removeChild(elem); // 클릭 후 a 요소 제거
          setTimeout(() => {
            setLoader({ show: false });
          }, 1000);
        });
        //
        //TODO - 이미지 다운 끝 밑 압축 후 다운로드(로딩 종료)
        //
      })
      .catch((error) => {
        setTimeout(() => {
          setLoader({ show: false });
        }, 1000);

        console.error('Error processing files:', error);
      });
  } catch (error) {
    console.log(error);
    setTimeout(() => {
      setLoader({ show: false });
    }, 1000);
  }
};

export const cellStatus = (filter: { key: string; name: string; active: boolean }[], key: string) => {
  return filter.find((i) => i.key === key)?.active ? 'table-cell' : 'none';
};

export const FILTERLIST = {
  PIPES: [
    { key: 'x', name: 'X', active: true },
    { key: 'y', name: 'Y', active: true },
    { key: 'z', name: 'Z', active: true },
    { key: 'avgDepth', name: '평균심도', active: true },
    { key: 'diameter', name: '관경', active: true },
    { key: 'distance', name: '연장', active: true },
    { key: 'mop', name: '재질', active: true },
    { key: 'pip', name: '구분', active: true },
    { key: 'stNearImg', name: '시점근경', active: true },
    { key: 'stDistantImg', name: '시점원경', active: true },
    { key: 'stElNearImg', name: '시점지반고근경', active: true },
    { key: 'stElDistantImg', name: '시점지반고원경', active: true },
    { key: 'endNearImg', name: '종점근경', active: true },
    { key: 'endDistantImg', name: '종점원경', active: true },
    { key: 'endElNearImg', name: '종점지반고근경', active: true },
    { key: 'endElDistantImg', name: '종점지반고원경', active: true },
  ],
  POINTS: [
    { key: 'x', name: 'X', active: true },
    { key: 'y', name: 'Y', active: true },
    { key: 'z', name: 'Z', active: true },
    { key: 'pit', name: '유형', active: true },
  ],
  PIPE_POINTS: [
    { key: 'x', name: 'X', active: true },
    { key: 'y', name: 'Y', active: true },
    { key: 'z', name: 'Z', active: true },
    { key: 'depth', name: '심도', active: true },
    { key: 'el', name: '지반고', active: true },
    { key: 'angle', name: '각도', active: true },
    { key: 'pit', name: '유형', active: true },
    { key: 'near', name: '근경', active: true },
    { key: 'distant', name: '원경', active: true },
    { key: 'elNear', name: '지반고근경', active: true },
    { key: 'elDistant', name: '지반고원경', active: true },
  ],
  HOLES: [
    { key: 'x', name: 'X', active: true },
    { key: 'y', name: 'Y', active: true },
    { key: 'z', name: 'Z', active: true },
    { key: 'high', name: '높이', active: true },
    { key: 'angle', name: '각도', active: true },
    { key: 'diameter', name: '구경', active: true },
    { key: 'mop', name: '재질', active: true },
    { key: 'sba', name: '종류', active: true },
    { key: 'for', name: '형태', active: true },
    { key: 'hol', name: '가로', active: true },
    { key: 'vel', name: '세로', active: true },
    { key: 'insideImg', name: '내경이미지', active: true },
    { key: 'outsideImg', name: '전경이미지', active: true },
  ],
  VALVES: [
    { key: 'x', name: 'X', active: true },
    { key: 'y', name: 'Y', active: true },
    { key: 'z', name: 'Z', active: true },
    { key: 'depth', name: '심도', active: true },
    { key: 'angle', name: '각도', active: true },
    { key: 'diameter', name: '관경', active: true },
    { key: 'mop', name: '재질', active: true },
    { key: 'pve', name: '종류', active: true },
    { key: 'near', name: '근경이미지', active: true },
    { key: 'distant', name: '원경이미지', active: true },
  ],
  FIRES: [
    { key: 'x', name: 'X', active: true },
    { key: 'y', name: 'Y', active: true },
    { key: 'z', name: 'Z', active: true },
    { key: 'angle', name: '각도', active: true },
    { key: 'diameter', name: '관경', active: true },
    { key: 'pfr', name: '종류', active: true },
    { key: 'near', name: '근경이미지', active: true },
    { key: 'distant', name: '원경이미지', active: true },
  ],
};

export interface FILE_TYPES_PROPS {
  type: 'point' | 'pipe' | 'image';
  text: string;
  acceptType: string[];
  acceptText: string;
  multiple: boolean;
}
export const FILE_TYPES: FILE_TYPES_PROPS[] = [
  {
    type: 'point',
    text: '측점',
    acceptType: ['text/csv'],
    acceptText: 'CSV',
    multiple: false,
  },
  {
    type: 'pipe',
    text: '관로',
    acceptType: ['text/csv'],
    acceptText: 'CSV',
    multiple: false,
  },
  {
    type: 'image',
    text: '이미지',
    acceptType: ['image/jpeg', 'image/jpg', 'image/png'],
    acceptText: '이미지(jpg, png)',
    multiple: true,
  },
];

// 상수(wtl), 오수, 우수, 가스, 통신, 전기, 송유, 난방
export const facilityKindByUrlCode = [
  { type: 'WATER', code: 'wtl' },
  { type: 'WASTE', code: 'wst' },
];

// 연결부(points), 맨홀(holes), 밸브(vales), 소화전(fires), 관로(pipes)
// 결과 : wlt-points, wtl-holes, wtl-vales, wtl-fires, wtl-pipes
// id 배열 map 해서 queries로 리턴

/* 배열 정렬하기 */
// 오름차순
export const getValueOfKeyAsc = (arr, key) => {
  let newArr = [...arr];
  return newArr.sort(function (a, b) {
    const valueOfKeyA = a[key].toUpperCase();
    const valueOfKeyB = b[key].toUpperCase();
    return valueOfKeyA.localeCompare(valueOfKeyB, undefined, {
      numeric: true,
      sensitivity: 'base',
    });
  });
};
//내림차순
export const getValueOfKeyDesc = (arr, key) => {
  let newArr = [...arr];
  return newArr.sort(function (a, b) {
    const valueOfKeyA = a[key].toUpperCase();
    const valueOfKeyB = b[key].toUpperCase();
    return valueOfKeyB.localeCompare(valueOfKeyA, undefined, {
      numeric: true,
      sensitivity: 'base',
    });
  });
};

export const menuObject = {
  SUPER_ADMIN: [
    {
      name: '고객사관리',
      nameEn: 'Auth',
      active: true,
      src: '/auth/list',
      id: 'auth',
      ico: IcoMenu01,
      activeIco: IcoMenu01Active,
    },
  ],
  ADMIN: {
    DEFAULT: [
      {
        name: '대시보드',
        nameEn: 'Dashboard',
        active: false,
        src: '/dashboard',
        id: 'dashboard',
        ico: IcoMenu01,
        activeIco: IcoMenu01Active,
      },
      {
        name: '2.5D지도',
        nameEn: '2.5D Map',
        active: false,
        src: '/map',
        id: 'map',
        ico: IcoMenu02,
        activeIco: IcoMenu02Active,
      },
      {
        name: '작업일보',
        nameEn: 'Daily Report',
        active: false,
        src: '/report',
        id: 'report',
        ico: IcoMenu03,
        activeIco: IcoMenu03Active,
      },
      {
        name: '측량성과등록',
        nameEn: '측량성과등록',
        active: false,
        src: '/survey/list',
        id: 'survey',
        ico: IcoMenu04,
        activeIco: IcoMenu04Active,
      },
      {
        name: '사업장관리',
        nameEn: '사업장관리',
        active: false,
        src: '/workplace/list',
        id: 'workplace',
        ico: IcoMenu03,
        activeIco: IcoMenu03Active,
      },
      {
        name: '매니저관리',
        nameEn: '매니저관리',
        active: false,
        src: '/manager/list',
        id: 'manager',
        ico: IcoMenu03,
        activeIco: IcoMenu03Active,
      },
      {
        name: '사용자관리',
        nameEn: '사용자관리',
        active: false,
        src: '/user/list',
        id: 'user',
        ico: IcoMenu03,
        activeIco: IcoMenu03Active,
      },
      {
        name: '설계등록',
        nameEn: '설계등록',
        active: false,
        src: '/design/list',
        id: 'design',
        ico: IcoMenu03,
        activeIco: IcoMenu03Active,
      },
      {
        name: '스마트핀등록',
        nameEn: '스마트핀등록',
        active: false,
        src: '/pin/list',
        id: 'pin',
        ico: IcoMenu03,
        activeIco: IcoMenu03Active,
      },
    ],
  },
  MANAGER: {
    DEFAULT: [
      {
        name: '대시보드',
        nameEn: 'Dashboard',
        active: true,
        src: '/dashboard',
        id: 'dashboard',
        ico: IcoMenu01,
        activeIco: IcoMenu01Active,
      },
      {
        name: '2.5D지도',
        nameEn: '2.5D Map',
        active: false,
        src: '/map',
        id: 'map',
        ico: IcoMenu02,
        activeIco: IcoMenu02Active,
      },
      {
        name: '작업일보',
        nameEn: 'Daily Report',
        active: false,
        src: '/report',
        id: 'report',
        ico: IcoMenu03,
        activeIco: IcoMenu03Active,
      },
      {
        name: '측량성과등록',
        nameEn: '측량성과등록',
        active: false,
        src: '/survey/list',
        id: 'survey',
        ico: IcoMenu04,
        activeIco: IcoMenu04Active,
      },
    ],
  },
  USER: {
    DEFAULT: [
      {
        name: '대시보드',
        nameEn: 'Dashboard',
        active: true,
        src: '/dashboard',
        id: 'dashboard',
        ico: IcoMenu01,
        activeIco: IcoMenu01Active,
      },
      {
        name: '2.5D지도',
        nameEn: '2.5D Map',
        active: false,
        src: '/map',
        id: 'map',
        ico: IcoMenu02,
        activeIco: IcoMenu02Active,
      },
      {
        name: '작업일보',
        nameEn: 'Daily Report',
        active: false,
        src: '/report',
        id: 'report',
        ico: IcoMenu03,
        activeIco: IcoMenu03Active,
      },
    ],
  },
};

export const RESOLUTION_TO_PROJ = (proj) => {
  switch (proj) {
    case 'EPSG:4326':
      return 0.00000000011248074543264695;
    default:
      return 0.00001;
  }
};
