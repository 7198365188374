import React, { useRef, useState, useEffect, useCallback } from 'react';
import { DragDropBoxSt } from './_style';

import { useTranslation } from 'react-i18next';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { confirmOneBtn, dragDropBox, popupFailState } from 'store/popupAtoms';

import { getFileType } from './common.func';
import { FILE_TYPES, FILE_TYPES_PROPS } from 'util/global';

function BasicDragDrop({
  height = 300,
  acceptType = ['image/jpeg', 'image/jpg', 'image/png', 'text/csv'],
  multiple = true,
  mainText,
  subText,
  curItem,
}: {
  height?: number;
  acceptType?: string[];
  multiple?: boolean;
  mainText?: string;
  subText?: string;
  curItem?: FILE_TYPES_PROPS;
}) {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  const [isDragging, setIsDragging] = useState(false); //드래그중 스타일
  const [fileArr, setFileArr] = useState<File[]>([]); // 받은 파일 배열

  const [dndState, setdndState] = useRecoilState(dragDropBox); // global
  const [confirmOneBtnState, setConfirmOneBtn] = useRecoilState(confirmOneBtn); // 경고문구 팝업

  // input element 초기화 (연속으로 같은 value 인식 못하는 부분 해결)
  const clearInput = () => {
    if (inputRef?.current) inputRef.current.value = '';
  };

  // 파일선택 버튼 클릭
  const onClickInput = () => {
    if (inputRef?.current) {
      inputRef.current.click();
    }
  };
  // 드래그 앤 드롭
  const onDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };
  const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };
  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files) {
      setIsDragging(true);
    }
  };

  // 공통 경고 알람
  const openAlert = (desc: string) => {
    setConfirmOneBtn((prev) => ({
      ...prev,
      isOpen: true,
      desc: desc,
    }));
  };

  // TODO: 파일 체크가 필요할 때
  const fileValidCheck = (files: any[]) => {
    if (!files.length) {
      return false;
    }

    const firstFile = files[0];
    const fileType = firstFile.type;
    const fileName = firstFile.name;
    console.log(fileType, fileName);

    // curItem 있을때 (이어서 업로드는 받을 수 있는 타입이 정해져있음)
    if (curItem) {
      if (curItem?.type !== getFileType(firstFile)) {
        setConfirmOneBtn((prev) => ({
          ...prev,
          isOpen: true,
          desc: `${curItem?.text} 파일을 선택하세요.`,
        }));
        return false;
      }
    }

    // 이미지 파일 일때
    if (fileType.startsWith('image/')) {
      // 5000개 이상은 막기
      if (files?.length > 1000) {
        openAlert('이미지 파일은 최대 1000개까지\n가능합니다.');
        return false;
      }
      setdndState((prev) => ({
        ...prev,
        type: 'image',
      }));
    }
    // csv 파일 일때
    if (fileType.includes('csv')) {
      if (files.length > 1) {
        openAlert('CSV 포함한 파일을\n여러개 업로드 할 수 없습니다.');
        return false;
      }
      if (!fileName.includes('point.csv') && !fileName.includes('pipe.csv')) {
        openAlert('파일명을 확인하세요.');
        return false;
      }
      if (fileName.includes('point.csv')) {
        setdndState((prev) => ({
          ...prev,
          type: 'point',
        }));
      }
      if (fileName.includes('pipe.csv')) {
        setdndState((prev) => ({
          ...prev,
          type: 'pipe',
        }));
      }
    }

    const fileTypeCheck = files.map((file) => {
      if (!acceptType.includes(file.type)) {
        openAlert('파일 타입을 확인하세요.');
        return false;
      }
    });
    if (fileTypeCheck.includes(false)) {
      return false;
    }

    //  console.log('fileValid?', file);
    return true;
  };

  // 1) 드래그 앤 드롭 완료 함수
  const onDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    setIsDragging(false);
    e.preventDefault();
    e.stopPropagation();

    const items = e.dataTransfer.items;
    const firstEntry = items[0].webkitGetAsEntry(); //(첫번째 항목으로 체크)

    if (firstEntry?.isDirectory) {
      // 디렉토리일때
      console.log('Drop Directory!');
      onDropDirectory(e);
    } else {
      // 파일 일때
      console.log('Drop File!');
      let files = e.dataTransfer.files || [];

      // 파일 유효성 검사
      if (!fileValidCheck([...files])) {
        setFileArr([]); // 유효성 실패시 초기화
        return; // 종료
      }
      // [통과시] state 저장
      await setFileArr([...files]);
    }

    // ref 초기화
    clearInput();
  };

  // 1-1) 드래그 드롭 -> folder
  const onDropDirectory = async (e: React.DragEvent<HTMLDivElement>) => {
    let newFiles = [];
    const items = e.dataTransfer.items;

    for (let i = 0; i < items.length; i++) {
      const item = items[i].webkitGetAsEntry();
      if (item) {
        if (item.isDirectory) {
          // 디렉토리일 경우
          //  console.log('Directory:', item.name);
          await processItem(item, newFiles);
        } else if (item.isFile) {
          // 파일일 경우
        }
      }
    }
    // 파일 유효성 검사
    if (!fileValidCheck([...newFiles])) {
      setFileArr([]); // 유효성 실패시 초기화
      return; // 종료
    }
    // [통과시] state 저장
    await setFileArr([...newFiles]);
  };

  // 2) input onchange 이벤트 완료 함수
  const onInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    let files = e.target.files || [];

    // 파일 유효성 검사
    if (!fileValidCheck([...files])) {
      setFileArr([]); // 유효성 실패시 초기화
      return; // 종료
    }

    // [통과시] state 저장
    await setFileArr([...files]);

    // ref 초기화
    clearInput();
  };

  // 디렉토리의 경우 reader -> processItem 재귀 호출
  const readDirectory = (directory, newFiles, resolve) => {
    const reader = directory.createReader();
    const readEntries = () => {
      reader.readEntries(async (entries) => {
        if (entries.length === 0) {
          resolve();
          return; // 종료
        }
        for (let i = 0; i < entries.length; i++) {
          //  console.log(entries[i]); // FileEntry
          await processItem(entries[i], newFiles);
        }
        readEntries(); // 재귀적으로 계속 읽음
      });
    };
    readEntries(); // 처음 호출
  };

  // 파일 item을 타입체크 - 배열에 저장
  const processItem = (item, newFiles) => {
    return new Promise<void>((resolve) => {
      if (item.isDirectory) {
        // 디렉토리 일때
        readDirectory(item, newFiles, resolve);
      } else if (item.isFile) {
        // 파일 일때
        item.file((file) => {
          // 이미지 경우 (이미지만 필터링)
          if (file.type.startsWith('image/')) {
            newFiles.push(file); // newFiles 배열에 파일 추가
          }
          resolve();
        });
      }
    });
  };

  // [최종] global state 저장
  useEffect(() => {
    console.log('fileArr change', fileArr);

    setdndState((prev) => ({
      ...prev,
      contentFileArr: [...fileArr],
    }));
  }, [fileArr, setdndState]);

  //   const firstFile = fileArr[0];
  //   const fileLength = fileArr.length;
  const firstFile = dndState.contentFileArr[0];
  const fileLength = dndState.contentFileArr.length;
  const disabled = false;

  //   console.log('dnd', dndState);

  return (
    <DragDropBoxSt.DragDropBox
      $height={height}
      className={`${disabled && 'disabled'} ${isDragging && 'isDragging'} ${firstFile && 'contentFile'}`}
      onDragEnter={!disabled ? onDragEnter : undefined}
      onDragLeave={!disabled ? onDragLeave : undefined}
      onDragOver={!disabled ? onDragOver : undefined}
      onDrop={!disabled ? onDrop : undefined}
    >
      {/* 도움말 없는 버전 for popup */}
      {firstFile ? (
        <div className="fileThumbWrap">
          <div className="icoWrap">
            <div className="ico"></div>
          </div>
          <p className="text">{firstFile?.name}</p>
          {fileArr.length > 1 && <p className="fileLength">{`외 ${fileLength - 1}개`} </p>}
        </div>
      ) : (
        <>
          <div className="ico"></div>
          <p className="mainText">{mainText ? mainText : t('여기에파일을드래그&드롭하여업로드하세요')}</p>
          <p className="subText">{subText ? subText : ''}</p>
          <p className="or">{t('또는')}</p>
        </>
      )}

      <button className="addFileBtn" onClick={onClickInput}>
        {firstFile ? t('파일다시선택') : t('파일선택')}
      </button>
      <input ref={inputRef} id="inputFile" type="file" accept={acceptType?.toString()} onChange={onInputChange} style={{ display: 'none' }} multiple={multiple} />
    </DragDropBoxSt.DragDropBox>
  );
}

export default BasicDragDrop;
